import api from '../utils/api';

const endpoint: string = `${process.env.REACT_APP_ENDPOINT}/api/auth/translateLanguage`;

const translateLanguage = (text: string) => {
  return api.post(`${endpoint}`, { text: text });
};

export default {
  translateLanguage
};
