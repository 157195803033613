import { AxiosResponse } from 'axios';
import { useDispatch, useSelector } from '../../store';
import BookingService from 'services/booking-management/BookingService';
import {
  setAllBooking,
  setAllTriageCandidates,
  updateOneBooking
} from 'store/reducers/booking-management/bookingSlice';
import {
  BookingFormType,
  BookingType,
  CreateBulkTriageType
} from 'types/bookingManagement';

type FileDownloadResponse = AxiosResponse<Blob>;

const useBooking = () => {
  const dispatch = useDispatch();
  const { triageCandidatesMeta, triageCandidates, bookings, bookingMeta } =
    useSelector(state => state.booking);

  const fetchAllTriageCandidates = async (
    page: number,
    limit: number,
    actorId?: string | null,
    venueId?: string | null,
    grade?: string | null,
    gender?: string | null
  ): Promise<BookingType[]> => {
    try {
      const resp = await BookingService.fetchAllTriageCandidates(
        page,
        limit,
        actorId,
        venueId,
        grade,
        gender
      );
      const meta = resp?.data?.data?.meta || null;
      const results = resp?.data?.data?.rows || [];
      dispatch(setAllTriageCandidates({ results, meta }));
      return results;
    } catch (e) {
      console.log('fetchAllTriageCandidates', e);
      throw e;
    }
  };
  const fetchAllBooking = async (
    page: number,
    limit: number,
    type?: string | null,
    actorId?: string | null,
    venueId?: string | null,
    grade?: string | null,
    gender?: string | null,
    dateInterval?: string,
    dateFrom?: string,
    dateTo?: string,
    bookingStatus?: string | null,
    search?: string,
    age_range?: string | null
  ): Promise<BookingType[]> => {
    try {
      const resp = await BookingService.fetchAllBooking(
        page,
        limit,
        type,
        actorId,
        venueId,
        grade,
        gender,
        dateInterval,
        dateFrom,
        dateTo,
        bookingStatus,
        search,
        age_range
      );
      const meta = resp?.data?.data?.meta || null;
      const results = resp?.data?.data?.rows || [];
      dispatch(setAllBooking({ results, meta }));
      return results;
    } catch (e) {
      console.log('fetchAllBooking', e);
      throw e;
    }
  };
  const fetchAllRecallPhoneStatus = async (
    patientId: number
  ): Promise<BookingType[]> => {
    try {
      const resp =
        await BookingService.fetchAllRecallPhoneHistoryByPatient(patientId);
      return resp?.data?.data?.rows || [];
    } catch (e) {
      console.log('fetchAllBooking', e);
      throw e;
    }
  };

  const createBulkTriage = async (data: CreateBulkTriageType[]) => {
    try {
      await BookingService.createBulkTriage(data);
      // const row = resp?.data?.data || null;
    } catch (e) {
      console.log('createBulkTriage', e);
      throw e;
    }
  };

  const updateBooking = async (id: number, data: BookingFormType) => {
    try {
      const resp = await BookingService.update(id, data);
      const row = resp?.data?.data || null;
      dispatch(updateOneBooking({ id, row }));
    } catch (e) {
      console.log('updateBooking', e);
      throw e;
    }
  };

  const exportBooking = async (
    isCandidates: boolean,
    type?: string | null,
    actorId?: string | null,
    venueId?: string | null,
    grade?: string | null,
    gender?: string | null,
    dateInterval?: string,
    dateFrom?: string,
    dateTo?: string,
    bookingStatus?: string | null
  ) => {
    try {
      await BookingService.exportBooking(
        isCandidates,
        type,
        actorId,
        venueId,
        grade,
        gender,
        dateInterval,
        dateFrom,
        dateTo,
        bookingStatus
      )
        .then((response: FileDownloadResponse) => {
          const url = window.URL.createObjectURL(new Blob([response?.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            isCandidates ? 'candidates.xlsx' : 'booked.xlsx'
          );
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch(error => {
          console.log('Error downloading the file:', error);
        });
    } catch (e) {
      console.log('downloadSampleFile', e);
      throw e;
    }
  };

  return {
    fetchAllTriageCandidates,
    fetchAllRecallPhoneStatus,
    fetchAllBooking,
    createBulkTriage,
    updateBooking,
    exportBooking,
    triageCandidates,
    triageCandidatesMeta,
    bookings,
    bookingMeta
  };
};

export default useBooking;
