import { FormOptionType } from '../../types/settings';
import { useEffect, useState } from 'react';
import { ProblemAdviceOptionType } from '../../components/forms/encounter-management/ScreeningAssessmentForm';
import useFormOptionHook from '../settings/useFormOption';

const useEncounterScreeningOptionHook = () => {
  const initProblemAdviceOptions = {
    fluoride_problem_advice: [],
    saliva_problem_advice: [],
    plaque_problem_advice: [],
    diet_problem_advice: []
  };

  const [problemOptions, setProblemOptions] = useState<ProblemAdviceOptionType>(
    initProblemAdviceOptions
  );
  const [adviceOptions, setAdviceOptions] = useState<ProblemAdviceOptionType>(
    initProblemAdviceOptions
  );

  const { fetchAllFormOption, formOptions } = useFormOptionHook();

  useEffect(() => {
    fetchAllFormOption().catch(e => console.log(e));
  }, []);
  useEffect(() => {
    let fluorideProblem: FormOptionType[] = [];
    let fluorideAdvice: FormOptionType[] = [];

    let salivaProblem: FormOptionType[] = [];
    let salivaAdvice: FormOptionType[] = [];

    let plaqueProblem: FormOptionType[] = [];
    let plaqueAdvice: FormOptionType[] = [];

    let dietProblem: FormOptionType[] = [];
    let dietAdvice: FormOptionType[] = [];

    if (formOptions.length > 0) {
      fluorideProblem = formOptions.filter(
        data => data.slug == 'fluoride-problem'
      );
      fluorideAdvice = formOptions.filter(
        data => data.slug == 'fluoride-advice'
      );

      salivaProblem = formOptions.filter(data => data.slug == 'saliva-problem');
      salivaAdvice = formOptions.filter(data => data.slug == 'saliva-advice');

      plaqueProblem = formOptions.filter(data => data.slug == 'plaque-problem');
      plaqueAdvice = formOptions.filter(data => data.slug == 'plaque-advice');

      dietProblem = formOptions.filter(data => data.slug == 'diet-problem');
      dietAdvice = formOptions.filter(data => data.slug == 'diet-advice');
    }

    setProblemOptions({
      fluoride_problem_advice: fluorideProblem,
      diet_problem_advice: dietProblem,
      plaque_problem_advice: plaqueProblem,
      saliva_problem_advice: salivaProblem
    });
    setAdviceOptions({
      fluoride_problem_advice: fluorideAdvice,
      diet_problem_advice: dietAdvice,
      plaque_problem_advice: plaqueAdvice,
      saliva_problem_advice: salivaAdvice
    });
  }, [formOptions]);

  return {
    problemOptions,
    adviceOptions
  };
};

export default useEncounterScreeningOptionHook;
