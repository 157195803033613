import api from '../../utils/api';
import { EncounterType } from '../../types/encounterManagement';

const authEndpoint: string = `${process.env.REACT_APP_ENDPOINT}/encounter`;
const fetchAll = ({ patientId }: { patientId: number }) => {
  return api.get(`${authEndpoint}?patient_id=${patientId}`);
};
const fetchOne = (id: number) => {
  return api.get(`${authEndpoint}/bulkEncounter/${id}`);
};

const create = (data: EncounterType) => {
  return api.post(`${authEndpoint}/bulkEncounter`, data);
};

const update = (id: number, data: EncounterType) => {
  return api.put(`${authEndpoint}/bulkEncounter/${id}`, data);
};
const destroy = (id: number) => {
  return api.delete(`${authEndpoint}/${id}`);
};
const fetchAllTabSetting = (type: string) => {
  return api.get(`${authEndpoint}/tabSettings/list?type=${type}&selected=1`);
};
const fetchAllFormSetting = (model: string) => {
  return api.get(`${authEndpoint}/formSetting/get?model=${model}`);
};
export default {
  fetchAll,
  fetchOne,
  create,
  update,
  destroy,
  fetchAllFormSetting,
  fetchAllTabSetting
};
