import { Form, Offcanvas, OffcanvasProps } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';

import { Formik } from 'formik';
import * as Yup from 'yup';
import { EncounterTreatmentCrownType } from '../../../types/encounterManagement';
import Button from '../../base/Button';
import { crownTypes } from '../../../data/encounterManagementData';
import TreatmentCrownDetail from '../../details/encounter-management/EncounterTreatment/TreatmentCrownDetail';

export interface TreatmentCrownFormProps {
  teeth: string | number;
  isView: boolean;
  data: EncounterTreatmentCrownType | null;
  onSubmit: (data: EncounterTreatmentCrownType) => void;
  onRemove: (data: EncounterTreatmentCrownType) => void;
  onClose: () => void;
  offCanvas: OffcanvasProps;
}

const TreatmentCrownForm = ({
  teeth,
  isView,
  data = {
    id: null,
    preparation: 'N',
    cementation: 'N',
    additional_details: '',
    other: '',
    crown_type: '',
    step: ''
  },
  offCanvas,
  onSubmit,
  onRemove,
  onClose
}: TreatmentCrownFormProps) => {
  // eslint-disable-next-line
  const { t }: { t: any } = useTranslation();
  const page = t('common.label.crown');
  const validationSchema = Yup.object({
    // name: Yup.string().required(
    //   t('component.form.validation.mandatory', {
    //     field: t('common.label.name').toLowerCase()
    //   })
    // )
  });
  const initialValues = useMemo(() => {
    const tempData: EncounterTreatmentCrownType = { ...data };
    if (tempData.preparation === 'Y') {
      tempData.step = 'preparation';
    } else if (tempData.cementation === 'Y') {
      tempData.step = 'cementation';
    }

    return tempData;
  }, [data]);
  const handleOnSubmit = async (values: EncounterTreatmentCrownType) => {
    const tempValues = { ...values };
    tempValues.preparation = tempValues.step === 'preparation' ? 'Y' : 'N';
    tempValues.cementation = tempValues.step === 'cementation' ? 'Y' : 'N';
    delete tempValues.step;
    onSubmit(tempValues);
  };
  const renderForm = () => {
    return (
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
      >
        {({ isSubmitting, values, handleBlur, handleChange, handleSubmit }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group className="mb-3 text-start">
              <div className="d-flex gap-4">
                <Form.Check
                  type="radio"
                  id="preparation"
                  label={t('common.label.preparation')}
                  value="preparation"
                  name="step"
                  onChange={handleChange}
                  checked={values.step == 'preparation'}
                />
                <Form.Check
                  type="radio"
                  id="cementation"
                  label={t('common.label.cementation')}
                  value="cementation"
                  name="step"
                  onChange={handleChange}
                  checked={values.step == 'cementation'}
                />
              </div>
              <Form.Group className="mb-3 text-start">
                <Form.Label htmlFor="other">
                  {t('common.label.other')}
                </Form.Label>
                <Form.Control
                  id="other"
                  type="text"
                  placeholder={t('common.label.other')}
                  className="form-control form-icon-input"
                  value={values.other || ''}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Form.Group>
            </Form.Group>

            <Form.Group className="mb-3 text-start">
              <Form.Label htmlFor="data">
                {t('common.label.types_of_crown')}
              </Form.Label>
              {crownTypes.map(field => (
                <Form.Check
                  type="radio"
                  key={field}
                  id={field}
                  label={`${t('common.label.' + field)}`}
                  value={field}
                  name="crown_type"
                  onChange={handleChange}
                  checked={values.crown_type == field}
                />
              ))}
              <Form.Check
                type="radio"
                key={'none'}
                id={'none'}
                label={`${t('common.label.none')}`}
                value="none"
                name="crown_type"
                onChange={handleChange}
                checked={values.crown_type == 'none'}
              />
            </Form.Group>

            <Form.Group className="mb-3 text-start">
              <Form.Label htmlFor="additional_details">
                {t('common.label.additional_details')}
              </Form.Label>
              <textarea
                rows={5}
                id="additional_details"
                name="additional_details"
                placeholder={t('common.label.additional_details')}
                className="form-control form-icon-input"
                value={values.additional_details || ''}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Group>
            <Form.Group className="mt-4 d-flex justify-content-between">
              <Button
                type="button"
                variant="subtle-danger"
                className=" mb-3"
                onClick={() => onRemove(data as EncounterTreatmentCrownType)}
                disabled={isSubmitting}
              >
                <Trans t={t}>common.action.remove</Trans>
              </Button>
              <Button
                type="submit"
                variant="primary"
                className=" mb-3"
                disabled={isSubmitting}
              >
                <Trans t={t}>common.action.save</Trans>
              </Button>
            </Form.Group>
          </Form>
        )}
      </Formik>
    );
  };
  const renderDetail = () => {
    return <TreatmentCrownDetail crown={data as EncounterTreatmentCrownType} />;
  };
  return (
    <Offcanvas onHide={onClose} {...offCanvas}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title id="example-modal-sizes-title-lg">
          {/*{initialValues.id ? `${t('common.label.edit')}` : `${t('common.label.add')}`}{' '}*/}
          <span className="me-2">
            {page}:&nbsp;{t('common.label.step_of_procedure')}
          </span>
          <span className="text-info">(Teeth:&nbsp;{teeth})</span>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>{isView ? renderDetail() : renderForm()}</Offcanvas.Body>
    </Offcanvas>
  );
};

export default TreatmentCrownForm;
