import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckSquare,
  faSquareXmark
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const CheckboxLabelItem = ({
  status,
  field
}: {
  status: string;
  field: string;
}) => {
  // eslint-disable-next-line
  const { t }: { t: any } = useTranslation();

  return (
    <span
      className={`d-flex gap-2  align-items-center ${status == 'Y' ? 'fw-bold' : ''}`}
    >
      <FontAwesomeIcon
        icon={status == 'Y' ? faCheckSquare : faSquareXmark}
        className={status == 'Y' ? `text-primary` : `text-secondary`}
      />
      <span>{t(`common.label.${field}`)}</span>
    </span>
  );
};

export default CheckboxLabelItem;
