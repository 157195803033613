import { useDispatch, useSelector } from 'store';

import TreatmentTypeService from 'services/encounter-management/TreatmentTypeService';
import { TreatmentTypesType } from 'types/encounterManagement';
import { setAllTreatmentType } from 'store/reducers/encounter-management/treatmentTypeSlice';

const useTreatmentType = () => {
  const dispatch = useDispatch();
  const { treatmentTypes } = useSelector(state => state.treatmentType);

  const fetchAllTreatmentType = async (): Promise<TreatmentTypesType[]> => {
    try {
      const resp = await TreatmentTypeService.fetchAll();
      let results: TreatmentTypesType[] = resp?.data?.rows || [];

      results = results.map(v => {
        const data = { ...v };
        const rolePrefix = 'encounter-treatment-types';
        switch (data.type_id) {
          case '01':
            data.color = '';
            data.color_code = '#1C6C09';
            data.type = 'whole_mouth_sdf';
            data.role = `${rolePrefix}-whole-mouth-sdf`;
            break;
          case '02':
            data.color = '';
            data.color_code = '#6e7891';
            data.type = 'fv_applied';
            data.role = `${rolePrefix}-fv-applied`;
            break;
          case '03':
            data.color = 'primary';
            data.color_code = '#094CD7';
            data.type = 'seal';
            data.role = `${rolePrefix}-seal`;
            break;
          case '04':
            data.color = 'success';
            data.color_code = '#25b003';
            data.type = 'art';
            data.role = `${rolePrefix}-art`;
            break;
          case '05':
            data.color = 'pink';
            data.color_code = '#d63384';
            data.type = 'periodontal';
            data.role = `${rolePrefix}-periodontal`;
            break;
          case '06':
            data.color = 'info';
            data.color_code = '#0097eb';
            data.type = 'filling';
            data.role = `${rolePrefix}-filling`;
            break;
          case '07':
            data.color = 'secondary';
            data.color_code = '#31374a';
            data.type = 'exo';
            data.role = `${rolePrefix}-exo`;
            break;
          case '08':
            data.color = 'warning';
            data.color_code = '#E5780B';
            data.type = 'crown';
            data.role = `${rolePrefix}-crown`;
            break;
          case '09':
            data.color = 'danger';
            data.color_code = '#EC1F00';
            data.type = 'root';
            data.role = `${rolePrefix}-root`;
            break;
          case '10':
            data.color = 'indigo';
            data.color_code = '#6610f2';
            data.type = 'untr';
            data.role = `${rolePrefix}-untr`;
            break;
          case '11':
            data.color = 'yellow';
            data.color_code = '#ffc107';
            data.type = 'sdf';
            data.role = `${rolePrefix}-sdf`;
            break;
          case '12':
            data.color = 'ref-h';
            data.color_code = '#1c4670';
            data.type = 'ref-h';
            data.role = `${rolePrefix}-ref-h`;
            break;
          default:
            data.color = '';
            data.type = '';
        }
        return data;
      });
      dispatch(setAllTreatmentType({ results }));
      return results;
    } catch (e) {
      console.log('fetchAllTreatmentType', e);
      throw e;
    }
  };
  return {
    treatmentTypes,
    fetchAllTreatmentType
  };
};

export default useTreatmentType;
