import Badge from 'components/base/Badge';
import { TFunction } from 'i18next';
import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

type TypeInfo = {
  target: {
    color: 'info';
    text: string;
  };
  t: {
    color: 'info';
    text: string;
  };
  'non-target': {
    color: 'secondary';
    text: string;
  };
  n: {
    color: 'secondary';
    text: string;
  };
  h: {
    color: 'success';
    text: string;
  };
  o: {
    color: 'primary';
    text: string;
  };
};

type Props = {
  type: string;
  style?: CSSProperties;
};

const TypeBadge: React.FC<Props> = props => {
  const { type, ...rest } = props;

  const { t }: { t: TFunction } = useTranslation();

  const typeInfo: TypeInfo = {
    t: {
      color: 'info',
      text: t('common.label.target')
    },
    target: {
      color: 'info',
      text: t('common.label.target')
    },
    'non-target': {
      color: 'secondary',
      text: t('common.label.non_target')
    },
    n: {
      color: 'secondary',
      text: t('common.label.non_target')
    },
    h: {
      color: 'success',
      text: t('common.label.high_school')
    },
    o: {
      color: 'primary',
      text: t('common.label.other')
    }
  };

  const badgeInfo = typeInfo[type?.toLowerCase() as keyof TypeInfo];

  if (!badgeInfo) {
    return null;
  }

  return (
    <Badge variant="phoenix" bg={badgeInfo.color} {...rest}>
      {badgeInfo.text}
    </Badge>
  );
};

export default TypeBadge;
