import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const monthsShort = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

export const defaultBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Page 1',
    url: '#!'
  },
  {
    label: 'Page 2',
    url: '#!'
  },
  {
    label: 'Default',
    active: true
  }
];

export const ecomBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Fashion',
    url: '#!'
  },
  {
    label: 'Womens Fashion',
    url: '#!'
  },
  {
    label: 'Footwear',
    url: '#!'
  },
  {
    label: 'Hills',
    active: true
  }
];
export const genderOption = [
  {
    label: 'Male',
    value: 'MALE'
  },
  {
    label: 'Female',
    value: 'FEMALE'
  },
  {
    label: 'Other',
    value: 'OTHER'
  }
];
export const maritalStatusOption = [
  {
    label: 'Single',
    value: 'SINGLE'
  },
  {
    label: 'Single',
    value: 'SINGLE'
  },
  {
    label: 'Widow/Widower',
    value: 'widow_or_widower'
  }
];
export const statusOption = [
  {
    label: 'Active',
    value: 'ACTIVE'
  },
  {
    label: 'Inactive',
    value: 'IN_ACTIVE'
  }
];

export const healthCenterStatusOptions = [
  {
    label: 'Active',
    value: 'A'
  },
  {
    label: 'Inactive',
    value: 'I'
  }
];

const currentYear = new Date().getFullYear();

export const yearOptions = Array.from({ length: 5 }, (_, i) => {
  const year = currentYear - i;
  return { label: year.toString(), value: year.toString() };
});

export const monthOptions = [
  {
    label: 'January',
    value: '01'
  },
  {
    label: 'February',
    value: '02'
  },
  {
    label: 'March',
    value: '03'
  },
  {
    label: 'April',
    value: '04'
  },
  {
    label: 'May',
    value: '05'
  },
  {
    label: 'June',
    value: '06'
  },
  {
    label: 'July',
    value: '07'
  },
  {
    label: 'August',
    value: '08'
  },
  {
    label: 'September',
    value: '09'
  },
  {
    label: 'October',
    value: '10'
  },
  {
    label: 'November',
    value: '11'
  },
  {
    label: 'December',
    value: '12'
  }
];

export const bookingTypeOptions = [
  {
    label: 'Recall',
    value: 'R'
  },
  {
    label: 'Triage',
    value: 'T'
  }
];

export const bookingStatusOptions = [
  {
    label: 'Scheduled',
    value: 'Scheduled'
  },
  {
    label: 'Re-Scheduled',
    value: 'Re-Scheduled'
  },
  {
    label: 'Note Added',
    value: 'Note Added'
  },
  {
    label: 'Encounter Created',
    value: 'Encounter Created'
  },
  {
    label: 'Encounter Happened',
    value: 'Encounter Happened'
  },
  {
    label: 'Expired',
    value: 'Expired'
  },
  {
    label: 'Cancelled',
    value: 'Cancelled'
  }
];

export const flagOptions = [
  {
    label: 'All',
    value: ''
  },
  {
    label: 'Flagged',
    value: '1'
  },
  {
    label: 'Un Flagged',
    value: '0'
  }
];

export const historicalYearsOptions = [
  {
    label: 'Past 1 Year',
    value: 1
  },
  {
    label: 'Past 2 Years',
    value: 2
  },
  {
    label: 'Past 3 Years',
    value: 3
  },
  {
    label: 'Past 4 Years',
    value: 4
  },
  {
    label: 'Past 5 Years',
    value: 5
  }
];

export const ageGroupOptions = [
  {
    label: '0-9',
    value: '0-9'
  },
  {
    label: '10-14',
    value: '10-14'
  },
  {
    label: '15-19',
    value: '15-19'
  },
  {
    label: '20-59',
    value: '20-59'
  },
  {
    label: '60-70',
    value: '60-70'
  },
  {
    label: '71-120',
    value: '71-120'
  }
];
