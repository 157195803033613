import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBed,
  faMapMarkerAlt,
  faPhone,
  faUsers
} from '@fortawesome/free-solid-svg-icons';
import { TFunction } from 'i18next';
import PhoenixLoader from 'components/common/PhoenixLoader';
import useActorHook from 'hooks/actor-management/useActorHook';
import useActivityTypeHook from 'hooks/actor-management/useActivityTypeHook';
import CustomBreadcrumb from 'components/base/CustomBreadcrumb';
import ActivityTypePatients from './ActivityTypePatients';
import { useDispatch } from 'store';
import { clearPatientList } from 'store/reducers/patient-management/patientSlice';
import { Card, Col, Row } from 'react-bootstrap';
import TypeBadge from 'components/common/TypeBadge';
import useVenueHook from 'hooks/actor-management/useVenueHook';

const ActivityTypePatientsJevaia = () => {
  const { t }: { t: TFunction } = useTranslation();
  const dispatch = useDispatch();
  const { activityTypeId, activityTypeVenueTypeId, actorId, venueId } =
    useParams();
  const { activityType, fetchOneActivityType } = useActivityTypeHook();
  const { actor, fetchOneActor } = useActorHook();

  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState<number>(1);

  const { venue, fetchOneVenue } = useVenueHook();

  const loadActorAndActivity = async () => {
    if (!actorId) return;

    try {
      setLoading(true);
      await Promise.all([
        activityTypeId
          ? fetchOneActivityType(parseInt(activityTypeId))
          : Promise.resolve(),
        actorId ? fetchOneActor(parseInt(actorId)) : Promise.resolve(),
        venueId ? fetchOneVenue(parseInt(venueId)) : Promise.resolve()
      ]);
    } catch (error) {
      console.error('Error loading actor or activity type:', error);
    } finally {
      setLoading(false);
    }
  };

  const setTabAndStore = (tabKey: number) => {
    setActiveTab(tabKey);
    dispatch(clearPatientList());
  };

  useEffect(() => {
    loadActorAndActivity();
  }, [activityTypeId, actorId, activityTypeVenueTypeId]);

  if (loading) {
    return <PhoenixLoader />;
  }

  const breadcrumbItems = [
    {
      label: activityType.name,
      link: `/activity-type/${activityTypeId}/${activityTypeVenueTypeId}`
    },
    {
      label: actor.name,
      link: `/activity-type/${activityTypeId}/${activityTypeVenueTypeId}/actor/${actorId}/schools`
    },
    {
      label: venue.name,
      isActive: true
    }
  ];

  const patientTitle = (
    <>
      <FontAwesomeIcon icon={faBed} />
      <span className="ms-1">{t('common.label.patients')}</span>
    </>
  );

  const wardPatientsTitle = (
    <>
      <FontAwesomeIcon icon={faUsers} />
      <span className="ms-1">Ward Patients</span>
    </>
  );

  const tabItems = [
    {
      key: 1,
      text: patientTitle,
      content: <ActivityTypePatients isTab withVenue />
    },
    {
      key: 2,
      text: wardPatientsTitle,
      content: <ActivityTypePatients isWardPatients isTab />
    }
  ];

  const SchoolCard = () => {
    return (
      <Card
        style={{
          width: '340px',
          border: '1px solid #CCCCCC',
          borderRadius: '5px',
          padding: 0
        }}
      >
        <Card.Body style={{ padding: '12px' }}>
          <TypeBadge
            type={venue?.type as string}
            style={{ position: 'absolute', top: 16, right: 16 }}
          />
          <div className="d-flex justify-content-between align-items-center pt-2 pb-3 pe-8 mt-3">
            <Card.Title
              className="mb-0 text-truncate"
              style={{ fontWeight: '700', fontSize: '18px' }}
              title={venue.name}
            >
              {venue.name}
            </Card.Title>
          </div>
          <Row>
            <Col className="text-truncate" md={7} style={{ fontSize: '13px' }}>
              <FontAwesomeIcon
                icon={faMapMarkerAlt}
                className="me-2 text-body-quaternary"
              />
              <span className="text-secondary fw-semibold">
                {venue.address}
              </span>
            </Col>
            <Col md={5} style={{ fontSize: '12px', textAlign: 'right' }}>
              <FontAwesomeIcon
                icon={faPhone}
                className="me-2 text-body-quaternary"
              />
              <span className="text-secondary fw-semibold">{venue.phone}</span>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  };

  return (
    <div>
      <Row className="align-items-center mb-4">
        <Col>
          <h3 className="mb-2">
            <span className="text-primary">{actor.name}</span>
          </h3>
          <CustomBreadcrumb items={breadcrumbItems} />
        </Col>
        <Col>
          <Row>
            <div className="d-flex justify-content-end">
              <SchoolCard />
            </div>
          </Row>
        </Col>
      </Row>
      {tabItems.length > 0 && (
        <div className="mb-2 nav nav-underline tab-underline pb-3">
          {tabItems.map(tab => (
            <div className="nav-item" key={tab.key}>
              <a
                role="button"
                className={`px-3 nav-link ${activeTab === tab.key ? 'active' : ''} nav-underline`}
                onClick={() => setTabAndStore(tab.key)}
              >
                {tab.text}
              </a>
            </div>
          ))}
        </div>
      )}
      {tabItems.map(
        tab => activeTab === tab.key && <div key={tab.key}>{tab.content}</div>
      )}
    </div>
  );
};

export default ActivityTypePatientsJevaia;
