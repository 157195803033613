import { EncounterTreatmentTeethType } from '../types/encounterManagement';

export const periodontalTeeths = [
  'teeth_17_14',
  'teeth_13_23',
  'teeth_24_27',
  'teeth_47_44',
  'teeth_43_33',
  'teeth_34_37'
];
export const xrayTypes = [
  {
    label: 'PBW',
    value: 'pbw'
  },
  {
    label: 'OPG',
    value: 'opg'
  },
  {
    label: 'PA',
    value: 'pa'
  },
  {
    label: 'Occlusal View',
    value: 'occlusal_view'
  }
];

export const treatmentTypeColors = [
  {
    type: 3,
    value: 'seal',
    color: 'primary'
  },

  {
    type: 4,
    value: 'art',
    color: 'success'
  },
  {
    type: 5,
    value: 'periodontal',
    color: 'pink'
  },
  {
    type: 6,
    value: 'filling',
    color: 'info'
  },
  {
    type: 7,
    value: 'exo',
    color: 'secondary'
  },
  {
    type: 8,
    value: 'crown',
    color: 'warning'
  },
  {
    type: 9,
    value: 'root',
    color: 'danger'
  },
  {
    type: 10,
    value: 'untr',
    color: 'indigo'
  },
  {
    type: 11,
    value: 'sdf',
    color: 'yellow'
  },
  {
    type: 12,
    value: 'ref-h',
    color: 'ref-h'
  }
];
export const firstQuartileTopTeethArray: EncounterTreatmentTeethType[] = [
  {
    teeth_number: 18,
    types: []
  },
  {
    teeth_number: 17,
    types: []
  },
  {
    teeth_number: 16,
    types: []
  },
  {
    teeth_number: 15,
    types: []
  },
  {
    teeth_number: 14,
    types: []
  },
  {
    teeth_number: 13,
    types: []
  },
  {
    teeth_number: 12,
    types: []
  },
  {
    teeth_number: 11,
    types: []
  }
];
export const firstQuartileBottomTeethArray: EncounterTreatmentTeethType[] = [
  {
    teeth_number: 55,
    types: []
  },
  {
    teeth_number: 54,
    types: []
  },
  {
    teeth_number: 53,
    types: []
  },
  {
    teeth_number: 52,
    types: []
  },
  {
    teeth_number: 51,
    types: []
  }
];
export const secondQuartileTopTeethArray: EncounterTreatmentTeethType[] = [
  {
    teeth_number: 21,
    types: []
  },
  {
    teeth_number: 22,
    types: []
  },
  {
    teeth_number: 23,
    types: []
  },
  {
    teeth_number: 24,
    types: []
  },
  {
    teeth_number: 25,
    types: []
  },
  {
    teeth_number: 26,
    types: []
  },
  {
    teeth_number: 27,
    types: []
  },
  {
    teeth_number: 28,
    types: []
  }
];
export const secondQuartileBottomTeethArray: EncounterTreatmentTeethType[] = [
  {
    teeth_number: 61,
    types: []
  },
  {
    teeth_number: 62,
    types: []
  },
  {
    teeth_number: 63,
    types: []
  },
  {
    teeth_number: 64,
    types: []
  },
  {
    teeth_number: 65,
    types: []
  }
];
export const thirdQuartileTopTeethArray: EncounterTreatmentTeethType[] = [
  {
    teeth_number: 71,
    types: []
  },
  {
    teeth_number: 72,
    types: []
  },
  {
    teeth_number: 73,
    types: []
  },
  {
    teeth_number: 74,
    types: []
  },
  {
    teeth_number: 75,
    types: []
  }
];
export const thirdQuartileBottomTeethArray: EncounterTreatmentTeethType[] = [
  {
    teeth_number: 31,
    types: []
  },
  {
    teeth_number: 32,
    types: []
  },
  {
    teeth_number: 33,
    types: []
  },
  {
    teeth_number: 34,
    types: []
  },
  {
    teeth_number: 35,
    types: []
  },
  {
    teeth_number: 36,
    types: []
  },
  {
    teeth_number: 37,
    types: []
  },
  {
    teeth_number: 38,
    types: []
  }
];
export const fourthQuartileTopTeethArray: EncounterTreatmentTeethType[] = [
  {
    teeth_number: 85,
    types: []
  },
  {
    teeth_number: 84,
    types: []
  },
  {
    teeth_number: 83,
    types: []
  },
  {
    teeth_number: 82,
    types: []
  },
  {
    teeth_number: 81,
    types: []
  }
];
export const fourthQuartileBottomTeethArray: EncounterTreatmentTeethType[] = [
  {
    teeth_number: 48,
    types: []
  },
  {
    teeth_number: 47,
    types: []
  },
  {
    teeth_number: 46,
    types: []
  },
  {
    teeth_number: 45,
    types: []
  },
  {
    teeth_number: 44,
    types: []
  },
  {
    teeth_number: 43,
    types: []
  },
  {
    teeth_number: 42,
    types: []
  },
  {
    teeth_number: 41,
    types: []
  }
];

export const cariesRisks = [
  {
    label: 'High',
    value: 'high'
  },
  {
    label: 'Medium',
    value: 'medium'
  },
  {
    label: 'Low',
    value: 'low'
  }
];

export const hmisCodes = [
  {
    label: 'Dental Caries (DA08.0)',
    value: 'DA08.0'
  },
  {
    label: 'Toothache (DA0A.Y)',
    value: 'DA0A.Y'
  },
  {
    label: 'Periodontal Disease (DA0D)',
    value: 'DA0D'
  },
  {
    label: 'Other Disorder of teeth (DA07.3)',
    value: 'DA07.3'
  },
  {
    label: 'Abscess & Facial Swelling (DA01.30)',
    value: 'DA01.30'
  },
  {
    label: 'Hypersensitivity (DA08.Y)',
    value: 'DA08.Y'
  }
];
export const assessmentTypes = [
  {
    label: 'fluoride',
    value: 'fluoride_problem_advice'
  },
  {
    label: 'saliva',
    value: 'saliva_problem_advice'
  },
  {
    label: 'plaque',
    value: 'plaque_problem_advice'
  },
  {
    label: 'diet',
    value: 'diet_problem_advice'
  }
];
export const crownTypes = [
  'stainless_steel_crown',
  'porcelain_fused_to_metal',
  'full_porcelain',
  'precious_metal',
  'non_precious_metal'
].sort((a, b) => a.toUpperCase().localeCompare(b.toUpperCase()));

export const fillingTypes = [
  'surface_distal',
  'surface_occlusal',
  'surface_buccal',
  'surface_palatal',
  'surface_lingual',
  'surface_mesial',
  'surface_labial',
  'surface_incisal'
].sort((a, b) => a.toUpperCase().localeCompare(b.toUpperCase()));

export const rootTypes = [
  'pulpectomy',
  'pulpotomy',
  'dressing',
  'preparation',
  'root_filling'
].sort((a, b) => a.toUpperCase().localeCompare(b.toUpperCase()));
