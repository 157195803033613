import React from 'react';
import { useTranslation } from 'react-i18next';

import { EncounterTreatmentCrownType } from '../../../../types/encounterManagement';
import { crownTypes } from '../../../../data/encounterManagementData';
import CheckboxLabelItem from '../../../common/CheckboxLabelItem';

interface TreatmentCrownDetailProps {
  crown: EncounterTreatmentCrownType;
}

const TreatmentCrownDetail: React.FC<TreatmentCrownDetailProps> = ({
  crown
}) => {
  // eslint-disable-next-line
  const { t }: { t: any } = useTranslation();

  return (
    <>
      <div className="d-flex gap-4">
        <CheckboxLabelItem
          status={crown?.preparation || 'N'}
          field="preparation"
        />
        <CheckboxLabelItem
          status={crown?.cementation || 'N'}
          field="cementation"
        />
      </div>
      <div className="mt-4">
        <h4> {t('common.label.other')}</h4>
        <p>{crown.other || 'N/A'}</p>
      </div>
      <div className="d-flex flex-column gap-4">
        <h4> {t('common.label.types_of_crown')}</h4>
        {crownTypes.map(field => {
          return (
            <CheckboxLabelItem
              key={field}
              status={crown.crown_type == field ? 'Y' : 'N'}
              field={field}
            />
          );
        })}
        <CheckboxLabelItem
          status={crown.crown_type == 'none' ? 'Y' : 'N'}
          field="none"
        />
      </div>
      <div className="mt-4">
        <h4> {t('common.label.additional_details')}</h4>
        <p>{crown.additional_details || 'N/A'}</p>
      </div>
    </>
  );
};

export default TreatmentCrownDetail;
