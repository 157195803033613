import { Form, Offcanvas, OffcanvasProps } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';

import { Formik } from 'formik';
import * as Yup from 'yup';
import { EncounterTreatmentRootType } from '../../../types/encounterManagement';
import Button from '../../base/Button';
import TreatmentRootDetail from '../../details/encounter-management/EncounterTreatment/TreatmentRootDetail';
import { rootTypes } from '../../../data/encounterManagementData';

export interface TreatmentRootFormProps {
  teeth: string | number;
  isView: boolean;
  data: EncounterTreatmentRootType | null;
  onSubmit: (data: EncounterTreatmentRootType) => void;
  onRemove: (data: EncounterTreatmentRootType) => void;
  onClose: () => void;
  offCanvas: OffcanvasProps;
}

const TreatmentRootForm = ({
  teeth,
  isView,
  data = {
    id: null,
    pulpectomy: 'N',
    pulpotomy: 'N',
    dressing: 'N',
    root_filling: 'N',
    preparation: 'N',
    additional_details: ''
  },
  offCanvas,
  onSubmit,
  onClose,
  onRemove
}: TreatmentRootFormProps) => {
  // eslint-disable-next-line
  const { t }: { t: any } = useTranslation();
  const page = t('common.label.root');
  const validationSchema = Yup.object({
    // name: Yup.string().required(
    //   t('component.form.validation.mandatory', {
    //     field: t('common.label.name').toLowerCase()
    //   })
    // )
  });
  const initialValues = useMemo(() => {
    const tempData: EncounterTreatmentRootType = { ...data };
    return tempData;
  }, [data]);
  const handleOnSubmit = async (values: EncounterTreatmentRootType) => {
    const tempValues = { ...values };

    onSubmit(tempValues);
  };

  const renderForm = () => {
    return (
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
      >
        {({
          isSubmitting,
          values,
          handleBlur,
          handleChange,
          setFieldValue,
          handleSubmit
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group className="mb-3 text-start">
              <Form.Label htmlFor="data">
                {t('common.label.types_of_root')}
              </Form.Label>
              {rootTypes.map(field => (
                <Form.Check
                  type="checkbox"
                  key={field}
                  id={field}
                  label={`${t('common.label.' + field)}`}
                  name="pulpectomy"
                  onChange={e =>
                    setFieldValue(field, e.target.checked ? 'Y' : 'N')
                  }
                  checked={
                    values[field as keyof EncounterTreatmentRootType] == 'Y'
                  }
                />
              ))}
            </Form.Group>

            <Form.Group className="mb-3 text-start">
              <Form.Label htmlFor="additional_details">
                {t('common.label.additional_details')}
              </Form.Label>
              <textarea
                rows={5}
                id="additional_details"
                name="additional_details"
                placeholder={t('common.label.additional_details')}
                className="form-control form-icon-input"
                value={values.additional_details || ''}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Group>
            <Form.Group className="mt-4 d-flex justify-content-between">
              <Button
                type="button"
                variant="subtle-danger"
                className=" mb-3"
                onClick={() => onRemove(data as EncounterTreatmentRootType)}
                disabled={isSubmitting}
              >
                <Trans t={t}>common.action.remove</Trans>
              </Button>
              <Button
                type="submit"
                variant="primary"
                className=" mb-3"
                disabled={isSubmitting}
              >
                <Trans t={t}>common.action.save</Trans>
              </Button>
            </Form.Group>
          </Form>
        )}
      </Formik>
    );
  };
  const renderDetail = () => {
    return <TreatmentRootDetail root={data as EncounterTreatmentRootType} />;
  };
  return (
    <Offcanvas onHide={onClose} {...offCanvas}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title id="example-modal-sizes-title-lg">
          {/*{initialValues.id ? `${t('common.label.edit')}` : `${t('common.label.add')}`}{' '}*/}
          <span className="me-2">
            {page}:&nbsp;{t('common.label.step_of_procedure')}
          </span>
          <span className="text-info">(Teeth:&nbsp;{teeth})</span>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>{isView ? renderDetail() : renderForm()}</Offcanvas.Body>
    </Offcanvas>
  );
};

export default TreatmentRootForm;
