import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActorType } from 'types/actorManagement';

interface ActorState {
  actors: ActorType[];
  actor: ActorType;
  selectedActor: ActorType | null;
}

const initialState: ActorState = {
  actors: [],
  actor: <ActorType>{},
  selectedActor: localStorage.getItem('selectedActor')
    ? JSON.parse(localStorage.getItem('selectedActor')!)
    : null
};

const actorSlice = createSlice({
  name: 'actor',
  initialState,
  reducers: {
    setAllActor(state, action: PayloadAction<{ results: ActorType[] }>) {
      state.actors = action.payload.results || [];
    },
    addOneActor(state, action: PayloadAction<{ row: ActorType }>) {
      state.actors = [...state.actors, action.payload.row];
    },
    updateOneActor(
      state,
      action: PayloadAction<{ id: number; row: Partial<ActorType> }>
    ) {
      state.actors = state.actors.map(item =>
        item.id !== action.payload.id
          ? item
          : { ...item, ...action.payload.row }
      );
    },
    setOneActor(state, action: PayloadAction<{ row: ActorType }>) {
      state.actor = action.payload.row;
    },
    removeOneActor(state, action: PayloadAction<{ id: number }>) {
      state.actors = state.actors.filter(item => item.id !== action.payload.id);
    },
    setSelectedActor(state, action: PayloadAction<ActorType | null>) {
      state.selectedActor = action.payload;
      localStorage.setItem('selectedActor', JSON.stringify(action.payload));
    },
    removeSelectedActor(state) {
      state.selectedActor = null;
      localStorage.removeItem('selectedActor');
    }
  }
});

export const {
  setAllActor,
  addOneActor,
  setOneActor,
  removeOneActor,
  updateOneActor,
  setSelectedActor,
  removeSelectedActor
} = actorSlice.actions;

export default actorSlice.reducer;
