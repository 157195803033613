import { Col, Form, Offcanvas, OffcanvasProps, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { PatientType } from '../../../types/patientManagement';
import Button from '../../base/Button';
import {
  FormSetting,
  SelectOptionType,
  validationErrorType
} from '../../../types';
import ValidationErrorMessage from '../../common/ValidationErrorMessage';
import ReactSelect from '../../base/ReactSelect';
import {
  genderOption,
  maritalStatusOption,
  statusOption
} from '../../../data/commonData';
import { ActivityType, ActorType, Venue } from '../../../types/actorManagement';
import {
  calculateAge,
  isoToDateString,
  showOrHideField
} from '../../../helpers/utils';
import { GeoLocationLevelType, GeoLocationType } from '../../../types/mdm';
import useFormOption from 'hooks/settings/useFormOption';
import DynamicLocationForm from '../mdm/DynamicLocationForm';
import usePatientHook from '../../../hooks/patient-management/usePatientHook';
import useVenueHook from '../../../hooks/actor-management/useVenueHook';
import useAuthHook from 'hooks/useAuthHook';

import '@sbmdkl/nepali-datepicker-reactjs/dist/index.css';
import NepaliDatePicker from '@sbmdkl/nepali-datepicker-reactjs';
import {
  convertADtoBs,
  isValidDate,
  limitDateNepali,
  todayDate
} from '../../../helpers/date';
import CustomDatePicker from 'components/base/CustomDatePicker';
import LanguageTranslationService from '../../../services/LanguageTranslationService';
import { debounce } from 'lodash';

export interface PatientFormProps {
  formData: PatientType;
  venues: Venue[];
  venueId?: number;
  actors: ActorType[];
  locations: GeoLocationType[];
  locationLevels: GeoLocationLevelType[];
  onSubmit: (data: PatientType) => void;
  onClose: () => void;
  offCanvas: OffcanvasProps;
  validationError?: validationErrorType;
  loading?: boolean;
  activityType?: ActivityType;
}

export interface PatientFormType extends PatientType {
  actorSelect?: SelectOptionType | null;
  venueSelect?: SelectOptionType | null;
  gradeSelect?: SelectOptionType | null;
  casteSelect?: SelectOptionType | null;
  educationLevelSelect?: SelectOptionType | null;
  locationSelect?: SelectOptionType[] | null;
}

const PatientForm = ({
  formData,
  venueId,
  activityType,
  venues,
  actors,
  locations,
  locationLevels,
  offCanvas,
  onSubmit,
  onClose,
  validationError,
  loading
}: PatientFormProps) => {
  // eslint-disable-next-line
  const { t }: { t: any } = useTranslation();
  const page = t('page.title.patient');

  const { fetchAllFormOption } = useFormOption();
  const { tenant } = useAuthHook();

  const isBLCP = tenant?.name?.toLowerCase() === 'blcp';

  const [venueOption, setVenueOption] = useState<SelectOptionType[]>([]);
  const [actorOption, setActorOption] = useState<SelectOptionType[]>([]);

  const [gradeOption, setGradeOption] = useState<SelectOptionType[]>([]);
  const [casteOptions, setCasteOptions] = useState<SelectOptionType[]>([]);
  const [educationLevelOptions, setEducationLevelOptions] = useState<
    SelectOptionType[]
  >([]);
  const [actorLocation, setActorLocation] = useState<string[] | undefined>(
    undefined
  );

  // const { fetchOneActor } = useActorHook();
  const { fetchOneVenue } = useVenueHook();
  const { fetchAllFormSettingPatient } = usePatientHook();
  const [patientFormSettings, setPatientFormSettings] = useState<FormSetting[]>(
    []
  );

  const [patientVenue, setPatientVenue] = useState<Venue | null>(null);

  useEffect(() => {
    if (patientFormSettings.length <= 0) {
      fetchAllFormSettingPatient('patients')
        .then(results => setPatientFormSettings(results || []))
        .catch(e => console.log(e));
    }
    setActorLocation(
      formData.location && formData.location.length > 0
        ? undefined
        : venues?.[0]?.location
    );

    const selectedVenueId = venueId ? venueId : (formData?.venue_id as number);

    if (selectedVenueId) {
      fetchOneVenue(selectedVenueId, false)
        .then(res => setPatientVenue(res))
        .catch(e => console.log(e));
    }
  }, [formData, venues, venueId]);

  const setActorItem = () => {
    const options: SelectOptionType[] = actors.map(data => {
      return {
        label: `${data.name}`,
        value: data.id
      };
    });

    setActorOption(options);
  };

  const setVenueItem = (actorId: number) => {
    const filterVenueOptions = venues
      .filter(data => data.actor_id == actorId)
      .map(data => {
        return {
          label: `${data.name} (${data.venue_type?.name})`,
          value: data.id
        };
      });
    setVenueOption(filterVenueOptions);
  };

  const initialValues = useMemo(() => {
    const tempFormData: PatientType = JSON.parse(JSON.stringify(formData));
    tempFormData.status =
      tempFormData.status == 'ACTIVE' ? 'ACTIVE' : 'IN_ACTIVE';
    if (!tempFormData.contact_number) {
      tempFormData.contact_number = venues?.[0]?.phone;
    }
    let actorSelect = null;
    if (tempFormData.actor_id) {
      const actorFilterOption = actors.filter(
        data => data.id == tempFormData.actor_id
      );
      if (actorFilterOption.length > 0) {
        actorSelect = {
          label: actorFilterOption[0].name,
          value: actorFilterOption[0].id
        };
      }

      // eslint-disable-next-line
      const venueList = [patientVenue].map((data: any) => {
        return {
          label: `${data?.name} (${data?.venue_type?.name})`,
          value: data?.id
        };
      });
      activityType
        ? setVenueOption(venueList)
        : setVenueItem(tempFormData.actor_id as number);
    }

    let venueSelect = null;

    if (tempFormData.venue_id) {
      const venueFilterOption = venues.filter(
        data => data.id == tempFormData.venue_id
      );
      if (venueFilterOption.length > 0) {
        tempFormData.actor_id = venueFilterOption[0].actor_id;

        venueSelect = {
          label: `${venueFilterOption[0].name} (${venueFilterOption[0].venue_type?.name})`,
          value: venueFilterOption[0].id
        };
      }
    }

    let gradeSelect = null;
    if (tempFormData.grade) {
      const gradeFilterOption = gradeOption.filter(
        data => data.value == tempFormData.grade
      );
      if (gradeFilterOption.length > 0) {
        gradeSelect = gradeFilterOption[0];
      }
    }

    let casteSelect = null;
    if (tempFormData.caste_ethnicity) {
      const casteFilterOption = casteOptions.filter(
        data => data.value == tempFormData.caste_ethnicity
      );
      if (casteFilterOption.length > 0) {
        casteSelect = casteFilterOption[0];
      }
    }

    let educationLevelSelect = null;
    if (tempFormData.education_level && educationLevelOptions?.length > 0) {
      const foundOption = educationLevelOptions.filter(
        data => data.value == tempFormData.education_level
      );
      educationLevelSelect = foundOption[0];
    }

    return {
      ...tempFormData,
      venueSelect,
      actorSelect,
      gradeSelect,
      casteSelect,
      educationLevelSelect
    };
  }, [formData, activityType, patientVenue]);

  const isFieldMandatory = (fieldName: string) => {
    return patientFormSettings?.find(item => item.field_name === fieldName)
      ?.mandatory;
  };

  const firstNameFieldLabel = isBLCP
    ? t('common.label.alt_first_name')
    : t('common.label.first_name');

  const middleNameFieldLabel = isBLCP
    ? t('common.label.alt_middle_name')
    : t('common.label.middle_name');

  const lastNameFieldLabel = isBLCP
    ? t('common.label.alt_last_name')
    : t('common.label.last_name');

  const altFirstNameFieldLabel = isBLCP
    ? t('common.label.first_name')
    : t('common.label.alt_first_name');

  const altMiddleNameFieldLabel = isBLCP
    ? t('common.label.middle_name')
    : t('common.label.alt_middle_name');

  const altLastNameFieldLabel = isBLCP
    ? t('common.label.last_name')
    : t('common.label.alt_last_name');

  const validationSchema = Yup.object({
    first_name: Yup.string().required(
      t('component.form.validation.mandatory', {
        field: firstNameFieldLabel.toLowerCase()
      })
    ),
    ...(isFieldMandatory('middle_name') && {
      middle_name: Yup.string().required(
        t('component.form.validation.mandatory', {
          field: middleNameFieldLabel.toLowerCase()
        })
      )
    }),
    last_name: Yup.string().required(
      t('component.form.validation.mandatory', {
        field: lastNameFieldLabel.toLowerCase()
      })
    ),
    ...(isFieldMandatory('alt_first_name') && {
      alt_first_name: Yup.string().required(
        t('component.form.validation.mandatory', {
          field: altFirstNameFieldLabel.toLowerCase()
        })
      )
    }),
    ...(isFieldMandatory('alt_middle_name') && {
      alt_middle_name: Yup.string().required(
        t('component.form.validation.mandatory', {
          field: altMiddleNameFieldLabel.toLowerCase()
        })
      )
    }),
    ...(isFieldMandatory('alt_last_name') && {
      alt_last_name: Yup.string().required(
        t('component.form.validation.mandatory', {
          field: altLastNameFieldLabel.toLowerCase()
        })
      )
    }),
    ...(!activityType && {
      venueSelect: Yup.object().required(
        t('component.form.validation.mandatory.select', {
          field: t('common.label.venue').toLowerCase()
        })
      )
    }),
    ...(!activityType && {
      actorSelect: Yup.object().required(
        t('component.form.validation.mandatory.select', {
          field: t('common.label.actor').toLowerCase()
        })
      )
    }),
    ...(isFieldMandatory('dob') && {
      dob: Yup.string().required(
        t('component.form.validation.mandatory', {
          field: t('common.label.dob').toLowerCase()
        })
      )
    }),
    ...(isFieldMandatory('alt_dob') && {
      alt_dob: Yup.string().required(
        t('component.form.validation.mandatory', {
          field: t('common.label.alt_dob').toLowerCase()
        })
      )
    }),
    ...(isFieldMandatory('contact_number')
      ? {
          contact_number: Yup.string()
            .matches(
              /^[0-9]+$/,
              t('component.form.validation.numeric', {
                field: t('common.label.phone').toLowerCase()
              })
            )
            .max(
              10,
              t('component.form.validation.max', {
                field: t('common.label.phone').toLowerCase(),
                max: 10
              })
            )
            .required(
              t('component.form.validation.mandatory', {
                field: t('common.label.phone').toLowerCase()
              })
            )
        }
      : {
          contact_number: Yup.string()
            .matches(
              /^[0-9]+$/,
              t('component.form.validation.numeric', {
                field: t('common.label.phone').toLowerCase()
              })
            )
            .max(
              10,
              t('component.form.validation.max', {
                field: t('common.label.phone').toLowerCase(),
                max: 10
              })
            )
        }),
    ...(isFieldMandatory('grade') && {
      gradeSelect: Yup.object().required(
        t('component.form.validation.mandatory.select', {
          field: t('common.label.grade').toLowerCase()
        })
      )
    }),
    ...(isFieldMandatory('caste_ethnicity') && {
      casteSelect: Yup.object().required(
        t('component.form.validation.mandatory.select', {
          field: t('common.label.caste_ethnicity').toLowerCase()
        })
      )
    }),
    ...(isFieldMandatory('age') && {
      age: Yup.string().required(
        t('component.form.validation.mandatory', {
          field: t('common.label.age').toLowerCase()
        })
      )
    }),
    ...(isFieldMandatory('address') && {
      address: Yup.string().required(
        t('component.form.validation.mandatory', {
          field: t('common.label.address').toLowerCase()
        })
      )
    }),
    ...(isFieldMandatory('tole') && {
      tole: Yup.string().required(
        t('component.form.validation.tole', {
          field: t('common.label.tole').toLowerCase()
        })
      )
    }),
    ...(isFieldMandatory('education_level') &&
      (educationLevelOptions?.length > 0
        ? {
            educationLevelSelect: Yup.object().required(
              t('component.form.validation.mandatory.select', {
                field: t('common.label.education_level').toLowerCase()
              })
            )
          }
        : {
            education_level: Yup.string().required(
              t('component.form.validation.mandatory', {
                field: t('common.label.education_level').toLowerCase()
              })
            )
          })),
    ...(isFieldMandatory('medical_history') && {
      medical_history: Yup.string().required(
        t('component.form.validation.mandatory', {
          field: t('common.label.medical_history').toLowerCase()
        })
      )
    }),
    ...(isFieldMandatory('dental_history') && {
      dental_history: Yup.string().required(
        t('component.form.validation.mandatory', {
          field: t('common.label.dental_history').toLowerCase()
        })
      )
    }),
    ...(isFieldMandatory('other_comments') && {
      other_comments: Yup.string().required(
        t('component.form.validation.mandatory', {
          field: t('common.label.other_comments').toLowerCase()
        })
      )
    })
    // locationSelect: Yup.array().min(locationLevels.length),
  });

  useEffect(() => {
    setActorItem();
    fetchAllFormOptionItem('grade');
    fetchAndSetCasteEthnicityItems();
    fetchAndSetEducationLevelItems();
  }, [formData]);

  const convertLanguage = async (text: string) => {
    try {
      if (tenant?.language_translation_enabled) {
        const resp = await LanguageTranslationService.translateLanguage(text);
        const inputs = resp?.data?.data || [];
        // console.log(inputs?.[0]?.text);
        return inputs?.[0]?.text || null;
      } else {
        return text;
      }
    } catch (e) {
      // console.log(e);
      return null;
    }
  };
  const fetchAllFormOptionItem = (slug: string) => {
    if (gradeOption.length <= 0) {
      fetchAllFormOption(slug)
        .then(results => {
          if (results.length > 0) {
            const mappedOptions: SelectOptionType[] = results.map(data => {
              return {
                label: data.label || '',
                value: data.value || ''
              };
            });
            setGradeOption(mappedOptions);
          }
        })
        .catch(e => console.log(e));
    }
  };

  const fetchAndSetEducationLevelItems = () => {
    fetchAllFormOption('education-level')
      .then(results => {
        if (results.length > 0) {
          const mappedOptions: SelectOptionType[] = results.map(data => {
            return {
              label: data.label || '',
              value: data.value || ''
            };
          });
          setEducationLevelOptions(mappedOptions);
        }
      })
      .catch(e => console.log(e));
  };

  const fetchAndSetCasteEthnicityItems = () => {
    fetchAllFormOption('casteethnicity')
      .then(results => {
        if (results.length > 0) {
          const mappedOptions: SelectOptionType[] = results.map(data => {
            return {
              label: data.label || '',
              value: data.value || ''
            };
          });
          setCasteOptions(mappedOptions);
        }
      })
      .catch(e => console.log(e));
  };

  const handleOnChangeActor = (
    // eslint-disable-next-line
    option: any
  ) => {
    const actorId = option?.value;
    setVenueItem(actorId as number);
  };

  const handleOnChangeVenue = (
    // eslint-disable-next-line
    option: any,
    // eslint-disable-next-line
    setFieldValue: any
  ) => {
    const selectedVenueId = option?.value;

    const foundVenue = venues.find(venue => venue.id === selectedVenueId);

    if (foundVenue) {
      setFieldValue('contact_number', foundVenue?.phone);
      setFieldValue('address', foundVenue?.address);
    }
  };

  const handleOnSubmit = async (values: PatientFormType) => {
    const formValues = JSON.parse(JSON.stringify(values));
    formValues.activity_type_id = activityType?.id;
    formValues.venue_id = activityType
      ? patientVenue?.id
      : values.venueSelect?.value;
    const venueFilter = venues.filter(data => data.id === formValues.venue_id);
    formValues.grade = values.gradeSelect?.value;
    formValues.caste_ethnicity = values.casteSelect?.value;
    formValues.education_level =
      values.educationLevelSelect?.value || values.education_level;

    formValues.actor_id = null;
    if (activityType) {
      formValues.actor_id = actors?.[0]?.id || null;
    } else {
      if (venueFilter.length > 0) {
        const venue = venueFilter[0];

        formValues.actor_id = venue.actor_id;
      }
    }

    formValues.location = [];
    if (values.locationSelect) {
      formValues.location = values.locationSelect
        .filter(data => data)
        .map(data => {
          if (data) return data.code;
        })
        .filter(data => data != null);
    }

    delete formValues.venueSelect;
    delete formValues.gradeSelect;
    delete formValues.locationSelect;
    delete formValues.actorSelect;
    onSubmit(formValues);
  };

  const renderName = (
    values: PatientFormType,
    touched: Partial<Record<keyof PatientFormType, boolean>>,
    errors: Partial<Record<keyof PatientFormType, string>>,
    { setFieldValue, setFieldTouched }: FormikHelpers<PatientFormType>
  ) => {
    const debouncedNameChangeHandler = useCallback(
      debounce(
        async (value: string, field: string) => {
          const tValue = await convertLanguage(value);
          setFieldValue(field, tValue);
        },
        300 // 300ms debounce time
      ),
      [setFieldValue] // Dependencies array to include setFieldValue
    );
    const lastName = () => {
      return (
        <Col md={4} sm={12}>
          <Form.Group className="mb-3 text-start">
            <Form.Label htmlFor="last_name">
              {t('common.label.last_name')}
            </Form.Label>
            <Form.Control
              id="last_name"
              name="last_name"
              type="text"
              placeholder={t('common.label.last_name')}
              className={`form-control form-icon-input ${
                touched.last_name && errors.last_name ? 'is-invalid' : ''
              }`}
              value={values.last_name || ''}
              onChange={async e => {
                setFieldValue('last_name', e?.target?.value || '');
                !values.alt_last_name &&
                  debouncedNameChangeHandler(e?.target?.value, 'alt_last_name');
              }}
              onBlur={() => {
                setFieldTouched('last_name', true);
              }}
            />
            {touched.last_name && errors.last_name && (
              <Form.Control.Feedback type="invalid">
                {errors.last_name}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
      );
    };
    const firstName = () => {
      return (
        <Col md={4} sm={12}>
          <Form.Group className="mb-3 text-start">
            <Form.Label htmlFor="first_name">
              {t('common.label.first_name')}
            </Form.Label>
            <Form.Control
              id="first_name"
              name="first_name"
              type="text"
              placeholder={t('common.label.first_name')}
              className={`form-control form-icon-input ${
                touched.first_name && errors.first_name ? 'is-invalid' : ''
              }`}
              value={values.first_name || ''}
              onBlur={() => {
                setFieldTouched('first_name', true);
              }}
              onChange={e => {
                setFieldValue('first_name', e?.target?.value || '');
                !values.alt_first_name &&
                  debouncedNameChangeHandler(
                    e?.target?.value,
                    'alt_first_name'
                  );
              }}
            />
            {touched.first_name && errors.first_name && (
              <Form.Control.Feedback type="invalid">
                {errors.first_name}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
      );
    };
    return (
      <Row>
        {isBLCP ? lastName() : firstName()}

        {showOrHideField(patientFormSettings, 'middle_name') && (
          <Col md={4} sm={12}>
            <Form.Group className="mb-3 text-start">
              <Form.Label htmlFor="middle_name">
                {t('common.label.middle_name')}
              </Form.Label>
              <Form.Control
                id="middle_name"
                name="middle_name"
                type="text"
                placeholder={t('common.label.middle_name')}
                className={`form-control form-icon-input ${
                  touched.middle_name && errors.middle_name ? 'is-invalid' : ''
                }`}
                value={values.middle_name || ''}
                onBlur={() => {
                  setFieldTouched('middle_name', true);
                }}
                onChange={e => {
                  setFieldValue('middle_name', e?.target?.value || '');
                  !values.alt_middle_name &&
                    debouncedNameChangeHandler(
                      e?.target?.value,
                      'alt_middle_name'
                    );
                }}
              />
              {touched.middle_name && errors.middle_name && (
                <Form.Control.Feedback type="invalid">
                  {errors.middle_name}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
        )}
        {isBLCP ? firstName() : lastName()}
      </Row>
    );
  };
  const renderAltName = (
    values: PatientFormType,
    touched: Partial<Record<keyof PatientFormType, boolean>>,
    errors: Partial<Record<keyof PatientFormType, string>>,
    { setFieldValue, setFieldTouched }: FormikHelpers<PatientFormType>
  ) => {
    const lastName = () => {
      if (showOrHideField(patientFormSettings, 'alt_last_name')) {
        return (
          <Col md={4} sm={12}>
            <Form.Group className="mb-3 text-start">
              <Form.Label htmlFor="alt_last_name">
                {t('common.label.alt_last_name')}
              </Form.Label>
              <Form.Control
                id="alt_last_name"
                name="alt_last_name"
                type="text"
                placeholder={t('common.label.alt_last_name')}
                className={`form-control form-icon-input ${
                  touched.alt_last_name && errors.alt_last_name
                    ? 'is-invalid'
                    : ''
                }`}
                value={values.alt_last_name || ''}
                // onBlur={handleBlur}
                onChange={e => {
                  setFieldValue('alt_last_name', e?.target?.value || '');
                }}
                onBlur={() => {
                  setFieldTouched('alt_last_name', true);
                }}
              />
              {touched.alt_last_name && errors.alt_last_name && (
                <Form.Control.Feedback type="invalid">
                  {errors.alt_last_name}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
        );
      } else {
        return null;
      }
    };

    const firstName = () => {
      if (showOrHideField(patientFormSettings, 'alt_first_name')) {
        return (
          <Col md={4} sm={12}>
            <Form.Group className="mb-3 text-start">
              <Form.Label htmlFor="alt_first_name">
                {t('common.label.alt_first_name')}
              </Form.Label>
              <Form.Control
                id="alt_first_name"
                name="alt_first_name"
                type="text"
                placeholder={t('common.label.alt_first_name')}
                className={`form-control form-icon-input ${
                  touched.alt_first_name && errors.alt_first_name
                    ? 'is-invalid'
                    : ''
                }`}
                value={values.alt_first_name || ''}
                onBlur={() => {
                  setFieldTouched('alt_first_name', true);
                }}
                onChange={e => {
                  setFieldValue('alt_first_name', e?.target?.value || '');
                }}
              />
              {touched.alt_first_name && errors.alt_first_name && (
                <Form.Control.Feedback type="invalid">
                  {errors.alt_first_name}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
        );
      } else {
        return null;
      }
    };
    return (
      <Row>
        {isBLCP ? lastName() : firstName()}
        {showOrHideField(patientFormSettings, 'alt_middle_name') && (
          <Col md={4} sm={12}>
            <Form.Group className="mb-3 text-start">
              <Form.Label htmlFor="alt_middle_name">
                {t('common.label.alt_middle_name')}
              </Form.Label>
              <Form.Control
                id="alt_middle_name"
                name="alt_middle_name"
                type="text"
                placeholder={t('common.label.alt_middle_name')}
                className={`form-control form-icon-input ${
                  touched.alt_middle_name && errors.alt_middle_name
                    ? 'is-invalid'
                    : ''
                }`}
                value={values.alt_middle_name || ''}
                onBlur={() => {
                  setFieldTouched('alt_middle_name', true);
                }}
                onChange={e => {
                  setFieldValue('alt_middle_name', e?.target?.value || '');
                }}
              />
              {touched.alt_middle_name && errors.alt_middle_name && (
                <Form.Control.Feedback type="invalid">
                  {errors.alt_middle_name}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
        )}
        {isBLCP ? firstName() : lastName()}
      </Row>
    );
  };

  return (
    <Offcanvas onHide={onClose} {...offCanvas} style={{ width: '50%' }}>
      <Offcanvas.Header className="px-6 pt-6 pb-0" closeButton>
        <Offcanvas.Title id="example-modal-sizes-title-lg">
          <h4>
            {formData.id ? t('common.label.edit') : t('common.label.add')}{' '}
            {page}
          </h4>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="px-6 pb-6">
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={handleOnSubmit}
        >
          {actions => {
            const {
              isSubmitting,
              values,
              handleBlur,
              handleChange,
              errors,
              touched,
              handleSubmit,
              setFieldValue,
              setFieldTouched
            } = actions;
            return (
              <Form noValidate onSubmit={handleSubmit}>
                {activityType && (
                  <Row>
                    <Col md={4} sm={12}>
                      <Form.Group className="mb-3 text-start">
                        <Form.Label htmlFor="activity_type_id">
                          {t('common.label.activity_type')}
                        </Form.Label>
                        <Form.Control
                          id="activity_type_name"
                          name="activity_type_name"
                          type="text"
                          className={`form-control form-icon-input`}
                          value={activityType?.name || ''}
                          disabled
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4} sm={12}>
                      <Form.Group className="mb-3 text-start">
                        <Form.Label htmlFor="actor_id">
                          {t('common.label.actor')}
                        </Form.Label>
                        <Form.Control
                          id="actor_name"
                          name="actor_name"
                          type="text"
                          className={`form-control form-icon-input`}
                          value={actors?.[0]?.name || ''}
                          disabled
                        />
                        <Form.Control
                          type="hidden"
                          id="actor_id"
                          name="actor_id"
                          value={values.actor_id || ''}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4} sm={12}>
                      <Form.Group className="mb-3 text-start">
                        <Form.Label htmlFor="venue_id">
                          {t('common.label.venue')}
                        </Form.Label>
                        <Form.Control
                          id="venue_name"
                          name="venue_name"
                          type="text"
                          className={`form-control form-icon-input`}
                          value={[patientVenue]?.[0]?.name || ''}
                          disabled
                        />
                        <Form.Control
                          type="hidden"
                          id="venue_id"
                          name="venue_id"
                          value={values.venue_id || ''}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                )}
                {renderName(values, touched, errors, actions)}
                {renderAltName(values, touched, errors, actions)}

                {!activityType && (
                  <Row>
                    <Col md={6} sm={12}>
                      <Form.Group className="mb-3 text-start">
                        <Form.Label htmlFor="actorSelect">
                          {t('common.label.actor')}
                        </Form.Label>

                        <ReactSelect
                          value={values.actorSelect}
                          options={actorOption}
                          name="actorSelect"
                          onChange={option => {
                            setFieldValue('actorSelect', option);
                            setFieldValue('venueSelect', null);
                            setActorLocation(undefined);
                            handleOnChangeActor(option);
                          }}
                          onBlur={() => setFieldTouched('actorSelect', true)}
                          placeholder={`${t('common.label.select')} ${t(
                            'common.label.actor'
                          ).toLowerCase()} ....`}
                        />
                        {touched.actorSelect && errors.actorSelect && (
                          <small className="text-danger">
                            {' '}
                            {errors.actorSelect}
                          </small>
                        )}
                      </Form.Group>
                    </Col>

                    <Col md={6} sm={12}>
                      <Form.Group className="mb-3 text-start">
                        <Form.Label htmlFor="venueSelect">
                          {t('common.label.venue')}
                        </Form.Label>
                        <ReactSelect
                          value={values.venueSelect}
                          options={venueOption}
                          name="venueSelect"
                          onChange={option => {
                            setFieldValue('venueSelect', option);
                            handleOnChangeVenue(option, setFieldValue);
                            // handleOnChangeActor(option);

                            // eslint-disable-next-line
                            const actorOption = option as any;
                            if (actorOption) {
                              fetchOneVenue(actorOption.value)
                                .then(resp =>
                                  setActorLocation(resp?.location || undefined)
                                )
                                .catch(e => console.log(e));
                            }
                          }}
                          onBlur={() => setFieldTouched('venueSelect', true)}
                          placeholder={`${t('common.label.select')} ${t(
                            'common.label.venue'
                          ).toLowerCase()} ....`}
                        />
                        {touched.venueSelect && errors.venueSelect && (
                          <small className="text-danger">
                            {' '}
                            {errors.venueSelect}
                          </small>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                )}

                <Row>
                  {showOrHideField(patientFormSettings, 'gender') && (
                    <Col md={4} sm={6} xs={12}>
                      <Form.Group className="mb-3 text-start">
                        <Form.Label>{t('common.label.gender')}</Form.Label>
                        <div className="d-flex gap-3">
                          {genderOption.map((opt, i) => (
                            <Form.Check
                              key={`gender-${i}`}
                              type="radio"
                              id={`gender-${opt.value}`}
                              label={opt.label}
                              name="gender"
                              value={opt.value}
                              checked={values.gender === opt.value}
                              onChange={event =>
                                setFieldValue(
                                  'gender',
                                  event?.target?.value || ''
                                )
                              }
                              onBlur={() => setFieldTouched('gender', true)}
                            />
                          ))}
                        </div>
                      </Form.Group>
                    </Col>
                  )}

                  {showOrHideField(patientFormSettings, 'dob') && (
                    <Col md={4} sm={6} xs={12}>
                      <Form.Group className="mb-3 text-start">
                        <Form.Label htmlFor="dob">
                          {t('common.label.dob')}
                        </Form.Label>
                        <CustomDatePicker
                          id="dob"
                          name="dob"
                          placeholder="Select DOB"
                          value={values.dob as string}
                          maxDate={new Date()}
                          // eslint-disable-next-line
                          onChange={(date: any) => {
                            const dateStr = isoToDateString(date);
                            setFieldValue('dob', dateStr);
                            setFieldValue('age', calculateAge(dateStr));
                          }}
                        />
                        {touched.dob && errors.dob && (
                          <small className="text-danger"> {errors.dob}</small>
                        )}
                      </Form.Group>
                    </Col>
                  )}

                  <Row>
                    <Col md={12} sm={12} xs={12}>
                      {showOrHideField(patientFormSettings, 'alt_dob') && (
                        <Col md={6} sm={12} xs={12}>
                          <Form.Group className="mb-3 text-start">
                            <Form.Label htmlFor="alt_dob">
                              {t('common.label.alt_dob')}
                            </Form.Label>
                            <NepaliDatePicker
                              className={'form-control'}
                              hideDefaultValue={
                                !(
                                  values?.alt_dob &&
                                  limitDateNepali(values.alt_dob) &&
                                  isValidDate(values.alt_dob)
                                )
                              }
                              defaultDate={
                                values.alt_dob &&
                                isValidDate(values.alt_dob) &&
                                limitDateNepali(values.alt_dob)
                                  ? values.alt_dob
                                  : ''
                              }
                              placeholder={`${t('common.label.select')} ${t(
                                'common.label.alt_dob'
                              ).toLowerCase()} ....`}
                              dateFormat={'YYYY-MM-DD'}
                              language={'en'}
                              theme={'default'}
                              maxDate={convertADtoBs(todayDate())}
                              onChange={({ bsDate }) => {
                                setFieldValue('alt_dob', bsDate);
                              }}
                            />
                            {touched.alt_dob && errors.alt_dob && (
                              <small className="text-danger">
                                {errors.alt_dob}
                              </small>
                            )}
                          </Form.Group>
                        </Col>
                      )}
                    </Col>
                  </Row>

                  {showOrHideField(patientFormSettings, 'contact_number') && (
                    <Col md={4} sm={6} xs={12}>
                      <Form.Group className="mb-3 text-start">
                        <Form.Label htmlFor="contact_number">
                          {t('common.label.phone')}
                        </Form.Label>
                        <Form.Control
                          id="contact_number"
                          name="contact_number"
                          placeholder={t('common.label.phone')}
                          className={`form-control form-icon-input ${
                            touched.contact_number && errors.contact_number
                              ? 'is-invalid'
                              : ''
                          }`}
                          value={values.contact_number || ''}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        {touched.contact_number && errors.contact_number && (
                          <Form.Control.Feedback type="invalid">
                            {errors.contact_number}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                  )}
                  {showOrHideField(patientFormSettings, 'grade') && (
                    <Col md={4} sm={6} xs={12}>
                      <Form.Group className="mb-3 text-start">
                        <Form.Label htmlFor="gradeSelect">
                          {t('common.label.grade')}
                        </Form.Label>
                        <ReactSelect
                          value={values.gradeSelect}
                          options={gradeOption}
                          name="gradeSelect"
                          onChange={option =>
                            setFieldValue('gradeSelect', option)
                          }
                          onBlur={() => setFieldTouched('gradeSelect', true)}
                          placeholder={`${t('common.label.select')} ${t(
                            'common.label.grade'
                          ).toLowerCase()} ....`}
                        />
                        {touched.gradeSelect && errors.gradeSelect && (
                          <small className="text-danger">
                            {errors.gradeSelect}
                          </small>
                        )}
                      </Form.Group>
                    </Col>
                  )}

                  {showOrHideField(patientFormSettings, 'marital_status') && (
                    <Col md={4} sm={6} xs={12}>
                      <Form.Group className="mb-3 text-start">
                        <Form.Label htmlFor="marital_status">
                          {t('common.label.marital_status')}
                        </Form.Label>
                        <Form.Select
                          id="marital_status"
                          value={values.marital_status}
                          name="marital_status"
                          onChange={event =>
                            setFieldValue(
                              'marital_status',
                              event?.target?.value || ''
                            )
                          }
                          onBlur={() => setFieldTouched('marital_status', true)}
                        >
                          {maritalStatusOption.map((opt, i) => (
                            <option
                              key={`marital_status-${i}`}
                              value={opt.value}
                            >
                              {opt.label}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  )}

                  {showOrHideField(patientFormSettings, 'caste_ethnicity') && (
                    <Col md={4} sm={6} xs={12}>
                      <Form.Group className="mb-3 text-start">
                        <Form.Label htmlFor="casteSelect">
                          {t('common.label.caste_ethnicity')}
                        </Form.Label>
                        <ReactSelect
                          value={values.casteSelect}
                          options={casteOptions}
                          name="casteSelect"
                          onChange={option =>
                            setFieldValue('casteSelect', option)
                          }
                          onBlur={() => setFieldTouched('casteSelect', true)}
                          placeholder={`${t('common.label.select')} ${t(
                            'common.label.caste_ethnicity'
                          ).toLowerCase()} ....`}
                        />
                        {touched.casteSelect && errors.casteSelect && (
                          <small className="text-danger">
                            {errors.casteSelect}
                          </small>
                        )}
                      </Form.Group>
                    </Col>
                  )}
                </Row>

                <Row>
                  <DynamicLocationForm
                    actorLocation={actorLocation}
                    formData={formData}
                    locations={locations}
                    locationLevels={locationLevels}
                    compact
                  />
                  {showOrHideField(patientFormSettings, 'address') && (
                    <Col md={4} sm={12}>
                      <Form.Group className="mb-3 text-start">
                        <Form.Label htmlFor="address">
                          {t('common.label.address')}
                        </Form.Label>
                        <Form.Control
                          id="address"
                          name="address"
                          type="text"
                          placeholder={t('common.label.address')}
                          className={`form-control form-icon-input ${
                            touched.address && errors.address
                              ? 'is-invalid'
                              : ''
                          }`}
                          value={values.address || ''}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        {touched.address && errors.address && (
                          <Form.Control.Feedback type="invalid">
                            {errors.address}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                  )}
                  {showOrHideField(patientFormSettings, 'tole') && (
                    <Col md={4} sm={12}>
                      <Form.Group className="mb-3 text-start">
                        <Form.Label htmlFor="tole">
                          {t('common.label.tole')}
                        </Form.Label>
                        <Form.Control
                          id="tole"
                          name="tole"
                          type="text"
                          placeholder={t('common.label.tole')}
                          className={`form-control form-icon-input ${
                            touched.tole && errors.tole ? 'is-invalid' : ''
                          }`}
                          value={values.tole || ''}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        {touched.tole && errors.tole && (
                          <Form.Control.Feedback type="invalid">
                            {errors.tole}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                  )}
                </Row>

                <Row>
                  {showOrHideField(patientFormSettings, 'age') && (
                    <Col md={4} sm={12}>
                      <Form.Group className="mb-3 text-start">
                        <Form.Label htmlFor="age">
                          {t('common.label.age')}
                        </Form.Label>
                        <Form.Control
                          id="age"
                          min="1"
                          name="age"
                          readOnly={showOrHideField(patientFormSettings, 'dob')}
                          type="number"
                          placeholder={t('common.label.age')}
                          className={`form-control form-icon-input ${
                            touched.age && errors.age ? 'is-invalid' : ''
                          }`}
                          value={values.age || ''}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        {touched.age && errors.age && (
                          <Form.Control.Feedback type="invalid">
                            {errors.age}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                  )}
                  {showOrHideField(patientFormSettings, 'education_level') && (
                    <Col md={4} sm={12}>
                      <Form.Group className="mb-3 text-start">
                        <Form.Label htmlFor="education_level">
                          {t('common.label.education_level')}
                        </Form.Label>
                        {educationLevelOptions?.length > 0 ? (
                          <>
                            <ReactSelect
                              value={values.educationLevelSelect}
                              options={educationLevelOptions}
                              name="educationLevelSelect"
                              onChange={option =>
                                setFieldValue('educationLevelSelect', option)
                              }
                              onBlur={() =>
                                setFieldTouched('educationLevelSelect', true)
                              }
                              placeholder={`${t('common.label.select')} ${t(
                                'common.label.education_level'
                              ).toLowerCase()} ....`}
                            />
                            {touched.educationLevelSelect &&
                              errors.educationLevelSelect && (
                                <small className="text-danger">
                                  {errors.educationLevelSelect}
                                </small>
                              )}
                          </>
                        ) : (
                          <>
                            <Form.Control
                              id="education_level"
                              name="education_level"
                              type="text"
                              placeholder={t('common.label.education_level')}
                              className={`form-control form-icon-input ${
                                touched.education_level &&
                                errors.education_level
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              value={values.education_level || ''}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                            {touched.education_level &&
                              errors.education_level && (
                                <Form.Control.Feedback type="invalid">
                                  {errors.education_level}
                                </Form.Control.Feedback>
                              )}
                          </>
                        )}
                      </Form.Group>
                    </Col>
                  )}
                  {showOrHideField(patientFormSettings, 'status') && (
                    <Col md={4} sm={12}>
                      <Form.Group className="mb-3 text-start">
                        <Form.Label htmlFor="status">
                          {t('common.label.status')}
                        </Form.Label>
                        <Form.Select
                          id="status"
                          value={values.status}
                          name="status"
                          onChange={event =>
                            setFieldValue(
                              'status',
                              event?.target?.value || 'ACTIVE'
                            )
                          }
                          onBlur={() => setFieldTouched('status', true)}
                        >
                          {statusOption.map((opt, i) => (
                            <option key={`status-${i}`} value={opt.value}>
                              {opt.label}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  )}
                </Row>
                <Row>
                  {showOrHideField(patientFormSettings, 'medical_history') && (
                    <Col md={12} sm={12}>
                      <Form.Group className="mb-3 text-start">
                        <Form.Label htmlFor="medical_history">
                          {t('common.label.medical_history')}
                        </Form.Label>
                        <textarea
                          id="medical_history"
                          name="medical_history"
                          placeholder={t('common.label.medical_history')}
                          className="form-control form-icon-input"
                          value={values.medical_history || ''}
                          onChange={handleChange}
                        />
                        {touched.medical_history && errors.medical_history && (
                          <small className="text-danger">
                            {errors.medical_history}
                          </small>
                        )}
                      </Form.Group>
                    </Col>
                  )}
                  {showOrHideField(patientFormSettings, 'dental_history') && (
                    <Col md={12} sm={12}>
                      <Form.Group className="mb-3 text-start">
                        <Form.Label htmlFor="dental_history">
                          {t('common.label.dental_history')}
                        </Form.Label>
                        <textarea
                          id="dental_history"
                          name="dental_history"
                          placeholder={t('common.label.medical_history')}
                          className="form-control form-icon-input"
                          value={values.dental_history || ''}
                          onChange={handleChange}
                        />
                        {touched.dental_history && errors.dental_history && (
                          <small className="text-danger">
                            {errors.dental_history}
                          </small>
                        )}
                      </Form.Group>
                    </Col>
                  )}
                  {showOrHideField(patientFormSettings, 'other_comments') && (
                    <Col md={12} sm={12}>
                      <Form.Group className="mb-3 text-start">
                        <Form.Label htmlFor="other_comments">
                          {t('common.label.other_comments')}
                        </Form.Label>
                        <textarea
                          id="other_comments"
                          name="other_comments"
                          placeholder={t('common.label.other_comments')}
                          className="form-control form-icon-input"
                          value={values.other_comments || ''}
                          onChange={handleChange}
                        />
                        {touched.other_comments && errors.other_comments && (
                          <small className="text-danger">
                            {errors.other_comments}
                          </small>
                        )}
                      </Form.Group>
                    </Col>
                  )}
                </Row>
                {validationError && (
                  <ValidationErrorMessage {...validationError} />
                )}
                <Row className="mt-4">
                  <Col sm={12} md={6}>
                    <Button
                      variant="subtle-secondary"
                      className="w-100 mb-3"
                      onClick={onClose}
                    >
                      <Trans t={t}>common.action.cancel</Trans>
                    </Button>
                  </Col>
                  <Col sm={12} md={6}>
                    <Button
                      type="submit"
                      variant="primary"
                      className="w-100 mb-3"
                      disabled={isSubmitting || loading}
                    >
                      <Trans t={t}>
                        common.action.{formData.id ? 'save' : 'add'}
                      </Trans>
                    </Button>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default PatientForm;
