import Badge from 'components/base/Badge';
import { TFunction } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';

type StatusInfo = {
  active: {
    color: 'info';
    text: string;
  };
  in_active: {
    color: 'secondary';
    text: string;
  };
  confirmed: {
    color: 'success';
    text: string;
  };
  cancelled: {
    color: 'danger';
    text: string;
  };
  unanswered: {
    color: 'warning';
    text: string;
  };
  scheduled: {
    color: 'success';
    text: string;
  };
  're-scheduled': {
    color: 'warning';
    text: string;
  };
  'note-added': {
    color: 'secondary';
    text: string;
  };
  'encounter-happened': {
    color: 'info';
    text: string;
  };
  'encounter-created': {
    color: 'info';
    text: string;
  };
  expired: {
    color: 'danger';
    text: string;
  };
  r: {
    color: 'success';
    text: string;
  };
  t: {
    color: 'info';
    text: string;
  };
  successful: {
    color: 'success';
    text: string;
  };
  pending: {
    color: 'warning';
    text: string;
  };
  failed: {
    color: 'danger';
    text: string;
  };
};

type Props = {
  status: string;
};

const StatusBadge: React.FC<Props> = props => {
  const { status } = props;

  const { t }: { t: TFunction } = useTranslation();

  const statusInfo: StatusInfo = {
    active: {
      color: 'info',
      text: t('common.label.active')
    },
    in_active: {
      color: 'secondary',
      text: t('common.label.inactive')
    },
    confirmed: {
      color: 'success',
      text: t('common.label.confirmed')
    },
    cancelled: {
      color: 'danger',
      text: t('common.label.cancelled')
    },
    unanswered: {
      color: 'warning',
      text: t('common.label.unanswered')
    },
    scheduled: {
      color: 'success',
      text: t('common.label.scheduled')
    },
    're-scheduled': {
      color: 'warning',
      text: t('common.label.re_scheduled')
    },
    'note-added': {
      color: 'secondary',
      text: t('common.label.note_added')
    },
    'encounter-happened': {
      color: 'info',
      text: t('common.label.encounter_created')
    },
    'encounter-created': {
      color: 'info',
      text: t('common.label.encounter_created')
    },
    expired: {
      color: 'danger',
      text: t('common.label.expired')
    },
    r: {
      color: 'success',
      text: t('common.label.recall')
    },
    t: {
      color: 'info',
      text: t('common.label.triage')
    },
    successful: {
      color: 'success',
      text: t('common.label.successful')
    },
    pending: {
      color: 'warning',
      text: t('common.label.pending')
    },
    failed: {
      color: 'danger',
      text: t('common.label.failed')
    }
  };

  const normalizedStatus = status?.toLowerCase().replace(/\s+/g, '-');
  const badgeInfo = statusInfo[normalizedStatus as keyof StatusInfo];

  if (!badgeInfo) {
    return null;
  }

  return (
    <Badge variant="phoenix" bg={badgeInfo.color}>
      {badgeInfo.text}
    </Badge>
  );
};

export default StatusBadge;
