import { BookingFormType, CreateBulkTriageType } from 'types/bookingManagement';
import api from '../../utils/api';

const listTriageCandiatateEndpoint: string = `${process.env.REACT_APP_ENDPOINT}/encounter/triage`;
const listBookingEndpoint: string = `${process.env.REACT_APP_ENDPOINT}/booking`;

const fetchAllTriageCandidates = (
  page: number,
  limit: number,
  actorId?: string | null,
  venueId?: string | null,
  grade?: string | null,
  gender?: string | null
) => {
  const params = new URLSearchParams();

  if (actorId) params.append('actor_id', actorId);
  if (venueId) params.append('venue_id', venueId);
  if (grade) params.append('grade', grade);
  if (gender) params.append('gender', gender);

  return api.get(
    `${listTriageCandiatateEndpoint}/candidates?page=${page}&limit=${limit}&${params.toString()}`
  );
};

const fetchAllBooking = (
  page: number,
  limit: number,
  type?: string | null,
  actorId?: string | null,
  venueId?: string | null,
  grade?: string | null,
  gender?: string | null,
  dateInterval?: string,
  dateFrom?: string,
  dateTo?: string,
  bookingStatus?: string | null,
  search?: string,
  age_range?: string | null
) => {
  const params = new URLSearchParams();

  if (type) params.append('type', type);
  if (actorId) params.append('actor_id', actorId);
  if (venueId) params.append('venue_id', venueId);
  if (grade) params.append('grade', grade);
  if (gender) params.append('gender', gender);
  if (dateInterval) params.append('date_interval', dateInterval);
  if (dateFrom) params.append('date_from', dateFrom);
  if (dateTo) params.append('date_to', dateTo);
  if (bookingStatus) params.append('booking_status', bookingStatus);
  if (search) params.append('search', search);
  if (age_range) params.append('age_range', age_range);

  return api.get(
    `${listBookingEndpoint}?page=${page}&limit=${limit}&${params.toString()}`
  );
};
const fetchAllRecallPhoneHistoryByPatient = (patientId: number) => {
  const params = new URLSearchParams();

  params.append('type', 'R');
  params.append('patient_id', `${patientId}`);
  params.append('has_app_status', `1`);

  return api.get(`${listBookingEndpoint}?${params.toString()}`);
};

const createBulkTriage = (data: CreateBulkTriageType[]) => {
  return api.post(`${listBookingEndpoint}/triage/bulk-save`, data);
};

const update = (id: number, data: BookingFormType) => {
  return api.put(`${listBookingEndpoint}/${id}`, data);
};

const exportBooking = (
  isCandidates: boolean,
  type?: string | null,
  actorId?: string | null,
  venueId?: string | null,
  grade?: string | null,
  gender?: string | null,
  dateInterval?: string,
  dateFrom?: string,
  dateTo?: string,
  bookingStatus?: string | null
) => {
  const params = new URLSearchParams();

  if (type) params.append('type', type);
  if (actorId) params.append('actor_id', actorId);
  if (venueId) params.append('venue_id', venueId);
  if (grade) params.append('grade', grade);
  if (gender) params.append('gender', gender);
  if (dateInterval) params.append('date_interval', dateInterval);
  if (dateFrom) params.append('date_from', dateFrom);
  if (dateTo) params.append('date_to', dateTo);
  if (bookingStatus) params.append('booking_status', bookingStatus);

  const endpoint = isCandidates
    ? `${listTriageCandiatateEndpoint}/export-candidates?${params.toString()}`
    : `${listBookingEndpoint}/export-bookings?${params.toString()}`;

  return api.get(endpoint, {
    responseType: 'blob'
  });
};

export default {
  fetchAllTriageCandidates,
  fetchAllRecallPhoneHistoryByPatient,
  fetchAllBooking,
  createBulkTriage,
  update,
  exportBooking
};
