import { GeoLocationLevelType } from '../../types/mdm';
import { useDispatch, useSelector } from '../../store';
import {
  addOneLocationLevel,
  removeOneLocationLevel,
  setAllLocationLevel,
  setOneLocationLevel
} from '../../store/reducers/mdm/locationLevelSlice';
import LocationLevelService from '../../services/mdm/LocationLevelService';

const useAuthHook = () => {
  const dispatch = useDispatch();
  const { locationLevels } = useSelector(state => state.locationLevel);

  const fetchAllLocationLevel = async (): Promise<GeoLocationLevelType[]> => {
    try {
      const resp = await LocationLevelService.fetchAll();
      const results = resp?.data?.data || [];
      dispatch(setAllLocationLevel({ results }));
      return results;
    } catch (e) {
      console.log('fetchAllLocationLevel', e);
      throw e;
    }
  };
  const createLocationLevel = async (
    data: GeoLocationLevelType
  ): Promise<GeoLocationLevelType> => {
    try {
      const resp = await LocationLevelService.create(data);
      const row = resp?.data?.data || null;
      dispatch(addOneLocationLevel({ row }));
      return row;
    } catch (e) {
      console.log('addOneLocationLevel', e);
      throw e;
    }
  };
  const updateLocationLevel = async (
    id: number,
    data: GeoLocationLevelType
  ) => {
    try {
      await LocationLevelService.update(id, data);
      dispatch(setOneLocationLevel({ id, row: data }));
    } catch (e) {
      console.log('getOneLocationLevel', e);
      throw e;
    }
  };
  const deleteLocationLevel = async (id: number) => {
    try {
      await LocationLevelService.destroy(id);
      dispatch(removeOneLocationLevel({ id }));
    } catch (e) {
      console.log('getOneLocationLevel', e);
      throw e;
    }
  };

  return {
    fetchAllLocationLevel,
    createLocationLevel,
    deleteLocationLevel,
    updateLocationLevel,
    locationLevels
  };
};

export default useAuthHook;
