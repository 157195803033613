import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Button from '../../base/Button';
import { Trans, useTranslation } from 'react-i18next';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface EncounterActionSectionProps {
  handleOnBack(key: string): void;

  handleOnNext(key: string): void;

  nextTab: string;

  backTab: string;
}

const EncounterActionSection = ({
  handleOnBack,
  handleOnNext,

  nextTab,
  backTab
}: EncounterActionSectionProps) => {
  // eslint-disable-next-line
  const { t }: { t: any } = useTranslation();

  return (
    <Row className="mt-4 mb-4">
      <Col className="d-flex justify-content-between align-items-center">
        <Button
          type="button"
          variant="subtle-secondary"
          disabled={!backTab}
          onClick={() => handleOnBack(backTab)}
        >
          <FontAwesomeIcon icon={faAngleLeft} className="fs-9 form-icon pe-3" />
          <Trans t={t}>common.action.back</Trans>
        </Button>

        <Button
          type="button"
          variant="subtle-secondary"
          disabled={!nextTab}
          onClick={() => handleOnNext(nextTab)}
        >
          <Trans t={t}>common.action.next</Trans>{' '}
          <FontAwesomeIcon
            icon={faAngleRight}
            className="fs-9 form-icon ps-3"
          />
        </Button>
      </Col>
    </Row>
  );
};

export default EncounterActionSection;
