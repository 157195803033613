import { GeoLocationTreeType, GeoLocationType } from '../../types/mdm';
import { useDispatch, useSelector } from '../../store';
import {
  addOneLocation,
  removeOneLocation,
  setAllLocation,
  setAllLocationTree,
  setOneLocation
} from '../../store/reducers/mdm/locationSlice';
import LocationService from '../../services/mdm/LocationService';

const useLocationHook = () => {
  const dispatch = useDispatch();
  const { locations, treeLocations } = useSelector(state => state.location);
  const fetchAllTreeLocation = async (): Promise<GeoLocationTreeType[]> => {
    try {
      const resp = await LocationService.treeView();
      const results = resp?.data?.data || [];
      dispatch(setAllLocationTree({ results }));
      return results;
    } catch (e) {
      console.log('treeViewLocation', e);
      throw e;
    }
  };
  const fetchAllLocation = async (): Promise<GeoLocationType[]> => {
    try {
      const resp = await LocationService.fetchAll();
      const results = resp?.data?.data || [];
      dispatch(setAllLocation({ results }));
      return results;
    } catch (e) {
      console.log('fetchAllLocation', e);
      throw e;
    }
  };
  const createLocation = async (
    data: GeoLocationType
  ): Promise<GeoLocationType> => {
    try {
      const resp = await LocationService.create(data);
      const row = resp?.data?.data || null;
      dispatch(addOneLocation({ row }));
      return row;
    } catch (e) {
      console.log('addOneLocation', e);
      throw e;
    }
  };
  const updateLocation = async (id: number, data: GeoLocationType) => {
    try {
      await LocationService.update(id, data);
      dispatch(setOneLocation({ id, row: data }));
    } catch (e) {
      console.log('getOneLocation', e);
      throw e;
    }
  };
  const deleteLocation = async (id: number) => {
    try {
      await LocationService.destroy(id);
      dispatch(removeOneLocation({ id }));
    } catch (e) {
      console.log('getOneLocation', e);
      throw e;
    }
  };

  return {
    fetchAllTreeLocation,
    fetchAllLocation,
    createLocation,
    deleteLocation,
    updateLocation,
    locations,
    treeLocations
  };
};

export default useLocationHook;
