import api from '../../utils/api';
import { GeoLocationType } from '../../types/mdm';

const authEndpoint: string = `${process.env.REACT_APP_ENDPOINT}/mdm/location`;

const treeView = () => {
  return api.get(`${authEndpoint}/tree`);
};
const fetchAll = () => {
  return api.get(`${authEndpoint}`);
};
const fetchOne = (id: number) => {
  return api.get(`${authEndpoint}/${id}`);
};

const create = (data: GeoLocationType) => {
  return api.post(`${authEndpoint}`, data);
};

const update = (id: number, data: GeoLocationType) => {
  return api.put(`${authEndpoint}/${id}`, data);
};
const destroy = (id: number) => {
  return api.delete(`${authEndpoint}/${id}`);
};

export default { treeView, fetchAll, fetchOne, create, update, destroy };
