import { useDispatch, useSelector } from '../../store';
import ActorService from '../../services/actor-management/ActorService';
import { ActorType } from '../../types/actorManagement';
import {
  addOneActor,
  removeOneActor,
  removeSelectedActor,
  setAllActor,
  setOneActor,
  setSelectedActor,
  updateOneActor
} from '../../store/reducers/actor-mangement/actorSlice';

const useActorHook = () => {
  const dispatch = useDispatch();
  const { actor, actors, selectedActor } = useSelector(state => state.actor);

  const fetchAllActor = async (): Promise<ActorType[]> => {
    try {
      const resp = await ActorService.fetchAll();
      const results = resp?.data?.data || [];
      dispatch(setAllActor({ results }));
      return results;
    } catch (e) {
      console.log('fetchAllActor', e);
      throw e;
    }
  };

  const fetchAllActorByActorTypeId = async (
    activityTypeId: number,
    activityTypeVenuId: number
  ): Promise<ActorType[]> => {
    try {
      const resp = await ActorService.fetchAllByActorTypeId(
        activityTypeId,
        activityTypeVenuId
      );
      return resp?.data?.data || [];
    } catch (e) {
      console.log('fetchAllActorByActorTypeId', e);
      throw e;
    }
  };

  const createActor = async (data: ActorType): Promise<ActorType> => {
    try {
      const resp = await ActorService.create(data);
      const row = resp?.data?.data || null;
      dispatch(addOneActor({ row }));
      return row;
    } catch (e) {
      console.log('addOneActor', e);
      throw e;
    }
  };

  const updateActor = async (id: number, data: ActorType) => {
    try {
      const resp = await ActorService.update(id, data);
      const row = resp?.data?.data || null;
      dispatch(updateOneActor({ id, row }));
    } catch (e) {
      console.log('getOneActor', e);
      throw e;
    }
  };

  const fetchOneActor = async (id: number): Promise<ActorType> => {
    try {
      const resp = await ActorService.fetchOne(id);
      const row = resp?.data?.data || null;
      dispatch(setOneActor({ row }));
      return row;
    } catch (e) {
      console.log('fetchOneActor', e);
      throw e;
    }
  };

  const deleteActor = async (id: number) => {
    try {
      await ActorService.destroy(id);
      dispatch(removeOneActor({ id }));
    } catch (e) {
      console.log('getOneActor', e);
      throw e;
    }
  };

  const selectActor = (actor: ActorType | null) => {
    dispatch(setSelectedActor(actor));
  };

  const clearSelectedActor = () => {
    dispatch(removeSelectedActor());
  };

  return {
    fetchAllActor,
    fetchOneActor,
    createActor,
    deleteActor,
    fetchAllActorByActorTypeId,
    updateActor,
    selectActor,
    clearSelectedActor,
    actors,
    actor,
    selectedActor
  };
};

export default useActorHook;
