import React from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { EncounterDiagnosticImage } from '../../types/encounterManagement';
import ImageAttachmentPreview from './ImageAttachmentPreview';

const XrayImagePreview = ({
  images,
  onRemove
}: {
  images: EncounterDiagnosticImage[];
  onRemove?: (selectedImg: EncounterDiagnosticImage) => void;
}) => {
  const { t }: { t: TFunction } = useTranslation();
  const assetEndPoint = process.env.REACT_APP_ASSET_ENDPOINT;
  return (
    <div className="d-flex flex-wrap" style={{ gap: '20px' }}>
      {images.map((img, index) => (
        <div
          key={index}
          className="d-flex flex-column "
          style={{ width: 'calc(25% - 20px)' }}
        >
          {onRemove ? (
            <ImageAttachmentPreview
              key={index}
              image={assetEndPoint + '/' + img.image_url}
              handleClose={() => onRemove(img)}
            />
          ) : (
            <ImageAttachmentPreview
              key={index}
              image={assetEndPoint + '/' + img.image_url}
            />
          )}
          <span className="fs-9 d-flex">
            <span className="text-secondary me-1">
              {t('common.label.title')}
            </span>{' '}
            |
            <span
              title={img?.label}
              className="text-break text-truncate cursor-pointer fw-bold ms-1 w-50"
            >
              {img?.label}
            </span>
          </span>
          <span className="fs-9 me-1">
            <span>{t('common.label.uploaded_by')}</span> |{' '}
            <span className="fw-bold ms-1">{img?.uploaded_by_name}</span>
          </span>
        </div>
      ))}
    </div>
  );
};

export default XrayImagePreview;
