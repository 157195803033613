import api from '../../utils/api';
import { ActorType } from '../../types/actorManagement';

const authEndpoint: string = `${process.env.REACT_APP_ENDPOINT}/actor-management/actor`;
const fetchAllWithPaginate = () => {
  return api.get(`${authEndpoint}`);
};
const fetchAll = () => {
  return api.get(`${authEndpoint}`);
};
const fetchAllByActorTypeId = (
  activityTypeId: number,
  activityTypeVenueTypeId: number
) => {
  return api.get(
    `${authEndpoint}?activity_type_id=${activityTypeId}&venue_type_id=${activityTypeVenueTypeId}`
  );
};
const fetchOne = (id: number) => {
  return api.get(`${authEndpoint}/${id}`);
};

const create = (data: ActorType) => {
  return api.post(`${authEndpoint}`, data);
};

const update = (id: number, data: ActorType) => {
  return api.put(`${authEndpoint}/${id}`, data);
};
const destroy = (id: number) => {
  return api.delete(`${authEndpoint}/${id}`);
};

export default {
  fetchAllWithPaginate,
  fetchAll,
  fetchOne,
  create,
  update,
  destroy,
  fetchAllByActorTypeId
};
