import { useDispatch, useSelector } from '../../store';
import PatientService from '../../services/patient-management/PateintService';
import { PatientType } from '../../types/patientManagement';
import {
  addOnePatient,
  removeOnePatient,
  setAllPatient,
  setOnePatient
} from '../../store/reducers/patient-management/patientSlice';

const usePatientHook = () => {
  const dispatch = useDispatch();
  const { meta, patients } = useSelector(state => state.patient);

  const fetchAllPatient = async (
    page: number,
    limit: number,
    search: string,
    actorId?: string | null,
    venueId?: string | null,
    grade?: string | null,
    isFlag?: string,
    age_range?: string | null
  ): Promise<PatientType[]> => {
    try {
      const resp = await PatientService.fetchAll(
        page,
        limit,
        search,
        actorId,
        venueId,
        grade,
        isFlag,
        age_range
      );
      const meta = resp?.data?.data?.meta || null;
      const results = resp?.data?.data?.rows || [];
      dispatch(setAllPatient({ results, meta }));
      return results;
    } catch (e) {
      console.log('fetchAllPatient', e);
      throw e;
    }
  };

  const fetchPatientsWithoutPagination = async (
    search: string,
    actorId?: string | null,
    venueId?: string | null,
    grade?: string | null,
    isFlag?: string
  ): Promise<PatientType[]> => {
    try {
      const resp = await PatientService.fetchAllWithoutPagination(
        search,
        actorId,
        venueId,
        grade,
        isFlag
      );
      const results = resp?.data?.data || [];
      dispatch(setAllPatient({ results }));
      return results;
    } catch (e) {
      console.log('fetchAllPatient', e);
      throw e;
    }
  };

  const fetchOnePatient = async (id: number): Promise<PatientType> => {
    try {
      const resp = await PatientService.fetchOne(id);
      const patient = resp?.data?.data || null;

      return patient;
    } catch (e) {
      console.log('fetchOnePatient', e);
      throw e;
    }
  };

  const createPatient = async (data: PatientType): Promise<PatientType> => {
    try {
      const resp = await PatientService.create(data);
      const row = resp?.data?.data || null;

      dispatch(addOnePatient({ row }));
      return row;
    } catch (e) {
      console.log('addOnePatient', e);
      throw e;
    }
  };
  const updatePatient = async (id: number, data: PatientType) => {
    try {
      const resp = await PatientService.update(id, data);
      const row = resp?.data?.data || null;
      dispatch(setOnePatient({ id, row }));
      return row;
    } catch (e) {
      console.log('getOnePatient', e);
      throw e;
    }
  };
  const deletePatient = async (id: number) => {
    try {
      await PatientService.destroy(id);
      dispatch(removeOnePatient({ id }));
    } catch (e) {
      console.log('getOnePatient', e);
      throw e;
    }
  };
  const countPatientByVenue = async (venueId: number) => {
    try {
      const resp = await PatientService.countByVenueId(venueId);
      return resp?.data?.data?.venue_count || 0;
    } catch (e) {
      console.log('countPatientByVenue', e);
      throw e;
    }
  };
  const fetchAllFormSettingPatient = async (model: string) => {
    try {
      const resp = await PatientService.fetchAllFormSetting(model);

      return resp?.data?.data || [];
    } catch (e) {
      console.log('fetchAllFormSettingPatient', e);
      throw e;
    }
  };
  const toggleFlagPatient = async (id: number) => {
    try {
      const resp = await PatientService.toggleFlag(id);

      const row = resp?.data?.data || null;
      dispatch(setOnePatient({ id, row }));
    } catch (e) {
      console.log('toggleFlagPatient', e);
      throw e;
    }
  };
  return {
    fetchAllPatient,
    fetchPatientsWithoutPagination,
    fetchOnePatient,
    createPatient,
    deletePatient,
    countPatientByVenue,
    updatePatient,
    fetchAllFormSettingPatient,
    toggleFlagPatient,
    meta,
    patients
  };
};

export default usePatientHook;
