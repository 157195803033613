import React from 'react';
import { Alert } from 'react-bootstrap';
import { validationErrorType } from '../../types';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

const ValidationErrorMessage = ({
  show = false,
  errors,
  onClose
}: validationErrorType) => {
  // eslint-disable-next-line
  const { t }: { t: any } = useTranslation();

  if (show) {
    const getErrorMessage = () => {
      const arrayErrors: { field: string; message: string }[] = [];

      Object.keys(errors).forEach(function (value) {
        arrayErrors.push({ field: value, message: errors[value] });
      });

      return arrayErrors;
    };

    return (
      <Alert variant="subtle-danger" onClose={onClose} dismissible>
        <Alert.Heading>
          {' '}
          <span className="  font-medium">
            <FontAwesomeIcon icon={faTriangleExclamation} className="me-2" />
            {t('common.message.failed.validation')}
          </span>
        </Alert.Heading>

        <div className="mt-4">
          {getErrorMessage().map((data, i) => (
            <p key={`validation-${i}`}>
              <b>{data.field}: </b>
              <span>{data.message}</span>
            </p>
          ))}
        </div>
      </Alert>
    );
  } else {
    return null;
  }
};

export default ValidationErrorMessage;
