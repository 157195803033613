import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { useTranslation } from 'react-i18next';
import { PatientType } from 'types/patientManagement';
import {
  getFirstAndLastName,
  getFullAltName,
  isTenantJevaia
} from 'helpers/utils';
import BlankTablePreview from 'components/common/BlankTablePreview';
import Button from '../../base/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag } from '@fortawesome/free-solid-svg-icons';

export const activityTypePatientsTableColumns = (
  handleOnView: (data: PatientType) => void
) => {
  const { t } = useTranslation();
  const isJevaia = isTenantJevaia();
  const columns: ColumnDef<PatientType>[] = [
    {
      header: t('common.label.patient_full_name'),
      accessorKey: 'full_name',
      cell: ({ row }) => {
        return (
          <span
            className="text-primary"
            style={{ cursor: 'pointer' }}
            onClick={() => handleOnView(row.original)}
          >
            {getFirstAndLastName(row.original)}
            {row.original.is_flagged ? (
              <Button
                variant="outline-danger"
                className="btn-circle"
                style={{ marginLeft: '4px', width: '1.2rem', height: '1.2rem' }}
              >
                <FontAwesomeIcon icon={faFlag} className="fs-10" />
              </Button>
            ) : (
              ''
            )}
          </span>
        );
      },
      meta: {
        cellProps: { className: 'text-body' },
        headerProps: { style: { width: '20%' }, className: '' }
      }
    },
    ...(isJevaia
      ? []
      : [
          {
            header: `${t('common.label.patient_full_alt_name')}`,
            accessorKey: 'alt_last_name',
            // eslint-disable-next-line
            cell: ({ row }: any) => {
              return getFullAltName(row.original);
            },
            meta: {
              cellProps: { className: 'text-body' },
              headerProps: { style: { width: '10%' }, className: '' }
            }
          }
        ]),
    ...(isJevaia
      ? []
      : [
          {
            header: `${t('common.label.grade')}`,
            accessorKey: 'grade',
            meta: {
              cellProps: { className: 'text-body text-center' },
              headerProps: { style: { width: '5%' }, className: 'text-center' }
            }
          }
        ]),
    {
      header: `${t('common.label.gender')}`,
      accessorKey: 'gender',
      cell: ({ row }) => {
        const gender = row.original.gender;
        return gender?.toLowerCase() === 'male' ? 'Male' : 'Female';
      },
      meta: {
        cellProps: { className: 'text-body text-center' },
        headerProps: { style: { width: '10%' }, className: 'text-center' }
      }
    },
    {
      header: `${t('common.label.age')}`,
      accessorKey: 'age',
      meta: {
        cellProps: { className: 'text-body text-center' },
        headerProps: { style: { width: '5%' }, className: 'text-center' }
      }
    },
    {
      header: `${t('common.label.phone')}`,
      accessorKey: 'contact_number',
      meta: {
        cellProps: { className: 'text-body text-right' },
        headerProps: { style: { width: '15%' }, className: 'text-right' }
      }
    },
    {
      header: `${t('common.label.encounters')}`,
      accessorKey: 'encounter_count',
      meta: {
        cellProps: { className: 'text-body text-center' },
        headerProps: { style: { width: '10%' }, className: 'text-center' }
      }
    }
  ];
  return columns;
};

const ActivityTypePatientsTable = ({ size }: { size?: number }) => {
  return (
    <div className="border-translucent">
      <AdvanceTable
        tableProps={{
          className: 'phoenix-table border-translucent fs-9'
        }}
      />
      {size && size > 0 ? (
        <AdvanceTableFooter pagination className="py-3" />
      ) : (
        <BlankTablePreview />
      )}
    </div>
  );
};

export default ActivityTypePatientsTable;
