import React, { useEffect, useMemo, useState } from 'react';
import { Offcanvas, OffcanvasProps } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBed,
  faBirthdayCake,
  faCalendar,
  faCheckCircle,
  faClipboard,
  faEdit,
  faGraduationCap,
  faLocationPin,
  faPerson,
  faPhone,
  faRing,
  faUniversity
} from '@fortawesome/free-solid-svg-icons';

import { PatientType } from '../../../types/patientManagement';

import Button from '../../base/Button';
import { getFullAltName, getFullName } from '../../../helpers/utils';
import StatusBadge from 'components/common/StatusBadge';
import { checkScope } from '../../../helpers/auth';
import usePatientHook from 'hooks/patient-management/usePatientHook';
import { FormSetting } from 'types';
import { TFunction } from 'i18next';
import useVenueHook from '../../../hooks/actor-management/useVenueHook';
import EncounterPage from '../../../pages/encounter-management/EncounterPage';
import useEncounterReason from '../../../hooks/encounter-management/useEncounterReason';
import { useParams } from 'react-router-dom';
import { Venue } from '../../../types/actorManagement';
import PhoenixLoader from '../../common/PhoenixLoader';
import { convertTimestampToHumanDateTimezone } from '../../../helpers/date';
import useBooking from '../../../hooks/booking-management.tsx/useBooking';
import { BookingType } from '../../../types/bookingManagement';

interface PatientDetailProps {
  data: PatientType;
  venueId?: number;
  onClose: () => void;
  onEdit: (data: PatientType) => void;
  offCanvas: OffcanvasProps;
}

const PatientDetail: React.FC<PatientDetailProps> = ({
  data,
  venueId,
  offCanvas,
  onEdit,
  onClose
}) => {
  const { t }: { t: TFunction } = useTranslation();
  const { activityTypeId } = useParams();
  const { venues, fetchAllVenue } = useVenueHook();

  const { fetchAllFormSettingPatient } = usePatientHook();
  const { fetchAllRecallPhoneStatus } = useBooking();
  const { reasons, fetchAllEncounterReason } = useEncounterReason();
  const [patientFormSettings, setPatientFormSettings] = useState<FormSetting[]>(
    []
  );
  const [venue, setVenue] = useState<Venue>();
  const [recallPhoneHistories, setRecallPhoneHistories] = useState<
    BookingType[]
  >([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    initLoadData().finally(() => setLoading(false));
    fetchAllRecallPhoneStatus(data?.id as number)
      .then(results => setRecallPhoneHistories(results))
      .catch(e => console.log(e));
  }, [venueId, data]);

  const initLoadData = async () => {
    try {
      if (!reasons?.length) {
        await fetchAllEncounterReason().catch(e => console.log(e));
      }
      if (patientFormSettings.length <= 0) {
        const tempPatientForm = await fetchAllFormSettingPatient('patients');
        setPatientFormSettings(tempPatientForm || []);
      }
      let tempVenues: Venue[] = [];
      if (venues.length <= 0) {
        tempVenues = await fetchAllVenue();
      } else {
        tempVenues = venues;
      }

      const tempVenue = tempVenues.find(v => v.id === venueId);

      setVenue(tempVenue);
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  const details = useMemo(() => {
    const isFieldSelected = (fieldName: string) => {
      return patientFormSettings?.find(item => item.field_name === fieldName)
        ?.selected;
    };
    return [
      {
        icon: faBed,
        label: t('common.label.patient_full_name'),
        value: getFullName(data),
        isSelected: true
      },
      {
        icon: faBed,
        label: t('common.label.patient_full_alt_name'),
        value: getFullAltName(data),
        isSelected: isFieldSelected('alt_first_name')
      },
      {
        icon: faPerson,
        label: t('common.label.gender'),
        value: data?.gender,
        isSelected: isFieldSelected('gender')
      },
      {
        icon: faCalendar,
        label: t('common.label.dob'),
        value: data?.dob,
        isSelected: isFieldSelected('dob')
      },
      {
        icon: faCalendar,
        label: t('common.label.alt_dob'),
        value: data?.alt_dob,
        isSelected: isFieldSelected('alt_dob')
      },

      {
        icon: faGraduationCap,
        label: t('common.label.grade'),
        value: data?.grade,
        isSelected: isFieldSelected('grade')
      },
      {
        icon: faBirthdayCake,
        label: t('common.label.age'),
        value: data?.age,
        isSelected: isFieldSelected('age')
      },
      {
        icon: faPhone,
        label: t('common.label.contact_no'),
        value: data?.contact_number,
        isSelected: isFieldSelected('contact_number')
      },

      {
        icon: faGraduationCap,
        label: t('common.label.education_level'),
        value: data?.education_level,
        isSelected: isFieldSelected('education_level')
      },
      ...(!activityTypeId
        ? [
            {
              icon: faUniversity,
              label: t('common.label.venue'),
              value: venue?.name,
              isSelected: true
            }
          ]
        : []),

      {
        icon: faRing,
        label: t('common.label.marital_status'),
        value: data?.marital_status?.toUpperCase(),
        isSelected: isFieldSelected('marital_status')
      },

      {
        icon: faCheckCircle,
        label: t('common.label.status'),
        value: <StatusBadge status={data?.status as string} />,
        isSelected: true
      },
      {
        icon: faLocationPin,
        label: t('common.label.address'),
        value: data?.address,
        isSelected: isFieldSelected('address')
      },
      {
        icon: faClipboard,
        label: t('common.label.encounters'),
        value: data?.encounter_count,
        isSelected: true
      },
      {
        icon: faClipboard,
        label: t('common.label.medical_history'),
        value: data?.medical_history || t('common.label.not_available'),
        isSelected: isFieldSelected('medical_history')
      },
      {
        icon: faClipboard,
        label: t('common.label.dental_history'),
        value: data?.dental_history || t('common.label.not_available'),
        isSelected: isFieldSelected('dental_history')
      },
      {
        icon: faClipboard,
        label: t('common.label.other_comments'),
        value: data?.other_comments || t('common.label.not_available'),
        isSelected: isFieldSelected('other_comments')
      }
    ];
  }, [activityTypeId, venue, data, patientFormSettings]);
  const renderRecallPhoneStatusHistory = () => {
    return (
      <div className="mt-5 mb-5">
        <h4>{t('common.label.recall_phone_status_history')}</h4>
        <hr />

        {recallPhoneHistories?.length > 0 ? (
          <>
            {recallPhoneHistories?.map((item, i) => (
              <>
                <div
                  key={`booking_details-list-${i}`}
                  className="mb-2 d-flex flex-column"
                >
                  <p className="fs-9 mb-0 ">
                    <span>
                      {convertTimestampToHumanDateTimezone(item?.updated_at)}
                    </span>
                    <span className="text-primary ms-2">
                      <StatusBadge status={item?.app_status as string} />
                    </span>
                  </p>
                  <p className="fs-9 mb-0">
                    <b>Note:</b> <span>{item?.app_notes}</span>
                  </p>
                </div>
              </>
            ))}
          </>
        ) : (
          <p className="fs-9 mb-0">Not found any recall phone status </p>
        )}
      </div>
    );
  };
  return (
    <Offcanvas onHide={onClose} {...offCanvas}>
      <Offcanvas.Header className="px-6 pt-6 pb-0" closeButton>
        <Offcanvas.Title className="d-flex align-items-center">
          <h4 className="mb-0">{t('page.title.patient_dtl')}</h4>
          {checkScope('patient-update') && !data?.is_flagged && (
            <Button variant="link" onClick={() => onEdit(data)}>
              <FontAwesomeIcon icon={faEdit} /> &nbsp;
              <span className="fw-bold">Edit</span>
            </Button>
          )}
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="px-6 pb-6">
        <table className="table table-borderless mb-5 p-2">
          {loading ? (
            <PhoenixLoader />
          ) : (
            <tbody>
              {details.map(({ icon, label, value, isSelected }, index) => {
                if (isSelected) {
                  return (
                    <tr key={index}>
                      <th className="d-flex align-items-center p-1">
                        <div style={{ width: '24px' }}>
                          <FontAwesomeIcon
                            icon={icon}
                            className="me-2 fs-9"
                            style={{ color: 'var(--phoenix-gray-500)' }}
                          />
                        </div>
                        <span className="fw-normal fs-9 text-secondary-dark">
                          {label}
                        </span>
                      </th>
                      <td className="p-1">
                        <span className="fw-semibold fs-9 text-black">
                          {value}
                        </span>
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          )}
        </table>

        <>{renderRecallPhoneStatusHistory()}</>
        {!loading &&
          activityTypeId &&
          venueId &&
          data?.status?.toUpperCase() == 'ACTIVE' && (
            <>
              <EncounterPage
                activityTypeId={parseInt(activityTypeId)}
                venueId={venueId as number}
                patient={data}
              />
            </>
          )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default PatientDetail;
