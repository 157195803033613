import { Form, Offcanvas, OffcanvasProps } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';

import { Formik } from 'formik';
import * as Yup from 'yup';
import { EncounterTreatmentFillingType } from '../../../types/encounterManagement';
import Button from '../../base/Button';
import TreatmentFillingDetail from '../../details/encounter-management/EncounterTreatment/TreatmentFillingDetail';
import { fillingTypes } from '../../../data/encounterManagementData';

export interface TreatmentFillingFormProps {
  teeth: string | number;
  isView: boolean;
  data: EncounterTreatmentFillingType | null;
  onSubmit: (data: EncounterTreatmentFillingType) => void;
  onRemove: (data: EncounterTreatmentFillingType) => void;

  onClose: () => void;
  offCanvas: OffcanvasProps;
}

const TreatmentFillingForm = ({
  teeth,
  isView,
  data = {
    id: null,
    has_filling_surface: 'N',
    filling_composite: 'N',
    filling_gic: 'N',
    filling_temporary: 'N',
    surface_occlusal: 'N',
    surface_distal: 'N',
    surface_buccal: 'N',
    surface_palatal: 'N',
    surface_lingual: 'N',
    surface_mesial: 'N',
    surface_labial: 'N',
    surface_incisal: 'N',
    details: '',
    step: ''
  },
  offCanvas,
  onSubmit,
  onRemove,
  onClose
}: TreatmentFillingFormProps) => {
  // eslint-disable-next-line
  const { t }: { t: any } = useTranslation();
  const page = t('common.label.filling');
  const validationSchema = Yup.object({
    has_filling_surface: Yup.string().required(
      'At least one filling surface should be checked.'
    ),
    step: Yup.string().required(
      'At least one Composite, GIC or Temporary should be checked.'
    )
  });
  const initialValues = useMemo(() => {
    const tempData: EncounterTreatmentFillingType = { ...data };
    if (tempData.filling_composite === 'Y') {
      tempData.step = 'filling_composite';
    } else if (tempData.filling_gic === 'Y') {
      tempData.step = 'filling_gic';
    } else if (tempData.filling_temporary === 'Y') {
      tempData.step = 'filling_temporary';
    } else {
      tempData.step = '';
    }
    return tempData;
  }, [data]);
  const handleOnSubmit = async (values: EncounterTreatmentFillingType) => {
    const tempValues = { ...values };
    tempValues.filling_composite =
      tempValues.step === 'filling_composite' ? 'Y' : 'N';
    tempValues.filling_gic = tempValues.step === 'filling_gic' ? 'Y' : 'N';
    tempValues.filling_temporary =
      tempValues.step === 'filling_temporary' ? 'Y' : 'N';
    delete tempValues.step;
    delete tempValues.has_filling_surface;
    onSubmit(tempValues);
  };
  const renderForm = () => {
    return (
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
      >
        {({
          isSubmitting,
          values,
          handleBlur,
          handleChange,
          setFieldValue,
          touched,
          errors,
          handleSubmit
        }) => {
          return (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group className="mb-3 text-start">
                <div className="d-flex gap-4">
                  <Form.Check
                    type="radio"
                    id="filling_composite"
                    label={t('common.label.filling_composite')}
                    value="filling_composite"
                    name="step"
                    onChange={handleChange}
                    checked={values.step == 'filling_composite'}
                  />
                  <Form.Check
                    type="radio"
                    id="filling_gic"
                    label={t('common.label.filling_gic')}
                    value="filling_gic"
                    name="step"
                    onChange={handleChange}
                    checked={values.step == 'filling_gic'}
                  />
                  <Form.Check
                    type="radio"
                    id="filling_temporary"
                    label={t('common.label.filling_temporary')}
                    value="filling_temporary"
                    name="step"
                    onChange={handleChange}
                    checked={values.step == 'filling_temporary'}
                  />
                </div>
                {touched.step && errors.step && (
                  <small className="text-danger">{errors.step}</small>
                )}
              </Form.Group>

              <Form.Group className="mb-3 text-start">
                <Form.Label htmlFor="surface">
                  {t('common.label.surface')}
                </Form.Label>
                {errors?.has_filling_surface && (
                  <div>
                    <small className="text-danger">
                      {errors?.has_filling_surface}
                    </small>
                  </div>
                )}

                {fillingTypes.map(field => (
                  <Form.Check
                    type="checkbox"
                    id={field}
                    key={field}
                    label={`${t('common.label.' + field)}`}
                    name={field}
                    onChange={e => {
                      setFieldValue(field, e.target.checked ? 'Y' : 'N');

                      const selectedFillingTypes = fillingTypes
                        .filter(
                          type =>
                            values?.[
                              type as keyof EncounterTreatmentFillingType
                            ] == 'Y'
                        )
                        .filter(type => type != field);

                      if (selectedFillingTypes.length > 0 || e.target.checked) {
                        setFieldValue('has_filling_surface', 'Y');
                      } else {
                        setFieldValue('has_filling_surface', '');
                      }
                    }}
                    checked={
                      values[field as keyof EncounterTreatmentFillingType] ==
                      'Y'
                    }
                  />
                ))}
              </Form.Group>

              <Form.Group className="mb-3 text-start">
                <Form.Label htmlFor="details">
                  {t('common.label.details')}
                </Form.Label>
                <Form.Control
                  id="details"
                  type="text"
                  placeholder={t('common.label.details')}
                  className="form-control form-icon-input"
                  value={values.details || ''}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mt-4 d-flex justify-content-between">
                <Button
                  type="button"
                  variant="subtle-danger"
                  onClick={() =>
                    onRemove(data as EncounterTreatmentFillingType)
                  }
                  className=" mb-3"
                  disabled={isSubmitting}
                >
                  <Trans t={t}>common.action.remove</Trans>
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  className=" mb-3"
                  disabled={isSubmitting}
                >
                  <Trans t={t}>common.action.save</Trans>
                </Button>
              </Form.Group>
            </Form>
          );
        }}
      </Formik>
    );
  };

  return (
    <Offcanvas onHide={onClose} {...offCanvas}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title id="example-modal-sizes-title-lg">
          {/*{initialValues.id ? `${t('common.label.edit')}` : `${t('common.label.add')}`}{' '}*/}
          <span className="me-2">
            {page}:&nbsp;{t('common.label.step_of_procedure')}
          </span>
          <span className="text-info">(Teeth:&nbsp;{teeth})</span>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {isView ? (
          <TreatmentFillingDetail
            fillings={data as EncounterTreatmentFillingType}
          />
        ) : (
          renderForm()
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default TreatmentFillingForm;
