import React, { useEffect, useMemo, useState } from 'react';
import { Card, Col, Form, OffcanvasProps, Row } from 'react-bootstrap';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  EncounterTreatmentCrownType,
  EncounterTreatmentFillingType,
  EncounterTreatmentRootType,
  EncounterTreatmentTeethType,
  EncounterTreatmentType,
  TreatmentTypesType
} from '../../../types/encounterManagement';
import EncounterActionSection from './EncounterActionSction';
import * as Yup from 'yup';
import TreatmentCrownForm from './TreatmentCrownForm';
import useTreatmentType from '../../../hooks/encounter-management/useTreatmentType';
import TreatmentRootForm from './TreatmentRootForm';
import TreatmentFillingForm from './TreatmentFillingForm';
import { commonAlert } from '../../common/CommonAlert';
import {
  firstQuartileBottomTeethArray,
  firstQuartileTopTeethArray,
  fourthQuartileBottomTeethArray,
  fourthQuartileTopTeethArray,
  secondQuartileBottomTeethArray,
  secondQuartileTopTeethArray,
  thirdQuartileBottomTeethArray,
  thirdQuartileTopTeethArray
} from '../../../data/encounterManagementData';
import { ActivityType } from '../../../types/actorManagement';
import { showOrHideFieldResource } from '../../../helpers/utils';
import { checkScope } from '../../../helpers/auth';

export interface TreatmentFormProps {
  nextTab?: string;
  backTab?: string;
  treatment?: EncounterTreatmentType | null;
  activityType?: ActivityType;
  isView?: boolean;
  isTriggerCompleted?: boolean;

  handleOnSubmit?(values: EncounterTreatmentType): void;

  handleOnBack?(key: string): void;

  handleOnNext?(key: string): void;
}

export interface TeethSectionType {
  firstQuartileTopTeeth: EncounterTreatmentTeethType[];
  firstQuartileBottomTeeth: EncounterTreatmentTeethType[];
  secondQuartileTopTeeth: EncounterTreatmentTeethType[];
  secondQuartileBottomTeeth: EncounterTreatmentTeethType[];
  thirdQuartileTopTeeth: EncounterTreatmentTeethType[];
  thirdQuartileBottomTeeth: EncounterTreatmentTeethType[];
  fourthQuartileTopTeeth: EncounterTreatmentTeethType[];
  fourthQuartileBottomTeeth: EncounterTreatmentTeethType[];
}

const TreatmentForm = ({
  treatment,
  activityType,

  handleOnBack,
  handleOnSubmit,
  handleOnNext,
  nextTab,
  backTab,
  isView = false,
  isTriggerCompleted = false
}: TreatmentFormProps) => {
  // eslint-disable-next-line
  const { t }: { t: any } = useTranslation();

  const initialValues = {
    id: null,
    treatment_type: [],
    fv_applied: 'N',
    whole_mouth_sdf: 'N',
    ohe_given: 'N',
    treatment_plan_completed: '',
    notes: ''
  };
  const [selectedTreatmentType, setSelectedTreatmentType] = useState<number>(3);
  const [selectedTeeth, setSelectedTeeth] = useState<number | string>('');
  const [selectedSection, setSelectedSection] = useState<
    keyof TeethSectionType
  >('firstQuartileTopTeeth');
  const [selectedCrown, setSelectedCrown] =
    useState<EncounterTreatmentCrownType | null>(null);
  const [selectedRoot, setSelectedRoot] =
    useState<EncounterTreatmentRootType | null>(null);
  const [selectedFilling, setSelectedFilling] =
    useState<EncounterTreatmentRootType | null>(null);
  const [treatmentTeeth, setTreatmentTeeth] = useState<TeethSectionType>({
    firstQuartileTopTeeth: firstQuartileTopTeethArray,
    firstQuartileBottomTeeth: firstQuartileBottomTeethArray,
    secondQuartileTopTeeth: secondQuartileTopTeethArray,
    secondQuartileBottomTeeth: secondQuartileBottomTeethArray,
    thirdQuartileTopTeeth: thirdQuartileTopTeethArray,
    thirdQuartileBottomTeeth: thirdQuartileBottomTeethArray,
    fourthQuartileTopTeeth: fourthQuartileTopTeethArray,
    fourthQuartileBottomTeeth: fourthQuartileBottomTeethArray
  });
  const [offCanvasCrown, setOffCanvasCrown] = useState<OffcanvasProps>({
    show: false,
    placement: 'end'
  });
  const [offCanvasRoot, setOffCanvasRoot] = useState<OffcanvasProps>({
    show: false,
    placement: 'end'
  });
  const [offCanvasFilling, setOffCanvasFilling] = useState<OffcanvasProps>({
    show: false,
    placement: 'end'
  });
  const { fetchAllTreatmentType } = useTreatmentType();
  const [treatmentTypes, setTreatmentTypes] = useState<TreatmentTypesType[]>(
    []
  );
  const formValues = useMemo<EncounterTreatmentType>(() => {
    if (treatment) {
      const tempTreatment: EncounterTreatmentType = { ...treatment };

      const treatmentTeethProperties = Object.keys(
        treatmentTeeth
      ) as (keyof TeethSectionType)[];

      if (
        tempTreatment.treatment_type &&
        tempTreatment.treatment_type?.length > 0
      ) {
        const tempTreatmentTeeth: TeethSectionType = JSON.parse(
          JSON.stringify(treatmentTeeth)
        );
        tempTreatment.treatment_type?.forEach(data => {
          treatmentTeethProperties.forEach(key => {
            tempTreatmentTeeth[key] = tempTreatmentTeeth[key].map(v => {
              if (v.teeth_number == data.teeth_number) {
                v = data;
              }
              return v;
            });
          });
        });
        tempTreatment.treatment_plan_completed = 'Y';

        setTreatmentTeeth(tempTreatmentTeeth);
      }

      return tempTreatment;
    } else {
      return initialValues;
    }
  }, [treatment]);

  useEffect(() => {
    fetchTreatmentTypeItems();
  }, [activityType]);

  const fetchTreatmentTypeItems = () => {
    const activityTreatmentTypes = activityType?.treatment_valid
      ? activityType.treatment_valid
      : [];
    fetchAllTreatmentType()
      .then(results => {
        let mappedResults = results;
        if (!isView)
          mappedResults = mappedResults.filter(
            data =>
              activityTreatmentTypes.includes(data.id as number) &&
              checkScope(data.role as string)
          );

        setTreatmentTypes(mappedResults);
      })
      .catch(e => console.log(e));
  };
  const getTreatmentColor = (type: number) => {
    let colorType = '';
    if (type) {
      const selectedTreatmentType = treatmentTypes.find(
        data => data.id == type
      );

      colorType = selectedTreatmentType?.type as string;
    }
    return colorType;
  };
  const getTreatment = (type: string) => {
    return treatmentTypes.find(data => data.type_id == type);
  };
  const getTreatmentTeethByQuartile = (section: keyof TeethSectionType) => {
    return JSON.parse(JSON.stringify(treatmentTeeth[section]));
  };
  const setTreatmentTeethQuartile = (
    section: keyof TeethSectionType,
    tempTreatmentTeeth: EncounterTreatmentTeethType[]
  ) => {
    setTreatmentTeeth({
      ...treatmentTeeth,
      ...{ [section]: tempTreatmentTeeth }
    });
  };
  const handleOnTreatmentType = (type: number) => {
    setSelectedTreatmentType(type);
  };
  const handleOnTeeth = (
    teeth_number: number,
    types: number[],
    section: keyof TeethSectionType
  ) => {
    const tempTreatmentTeeth: EncounterTreatmentTeethType[] =
      getTreatmentTeethByQuartile(section);
    setSelectedTeeth(teeth_number);
    if (!isView) {
      if (types.length > 0) {
        const filterOtherTeethWithTypes = tempTreatmentTeeth.filter(
          item =>
            item.teeth_number === teeth_number &&
            !item.types.includes(selectedTreatmentType)
        );
        //
        if (filterOtherTeethWithTypes.length > 0) {
          commonAlert({
            title: `${t('common.label.error')}`,
            textColor: 'danger',
            message: `${t('common.message.teeth.selection.error')}`
          });
          return false;
        }
      }
      const selectedTeethByType: EncounterTreatmentTeethType | null =
        tempTreatmentTeeth.filter(
          item =>
            item.teeth_number === teeth_number &&
            item.types.includes(selectedTreatmentType)
        )[0] || null;

      setSelectedSection(section);
      switch (selectedTreatmentType) {
        case 8:
          setOffCanvasCrown({ ...offCanvasCrown, ...{ show: true } });
          setSelectedCrown(
            (selectedTeethByType && selectedTeethByType.crown) || null
          );

          break;
        case 9:
          setOffCanvasRoot({ ...offCanvasRoot, ...{ show: true } });
          setSelectedRoot(
            (selectedTeethByType && selectedTeethByType.root) || null
          );
          break;
        case 6:
          setOffCanvasFilling({ ...offCanvasFilling, ...{ show: true } });
          setSelectedFilling(
            (selectedTeethByType && selectedTeethByType.fillings) || null
          );
          break;
        default:
          setSelectedCrown(null);
          setSelectedRoot(null);
          setSelectedFilling(null);
          setTreatmentTeethWithType(
            { teeth_number, types: [selectedTreatmentType] },
            section,
            true
          );
      }
    } else {
      setOffCanvasRoot({ ...offCanvasRoot, ...{ show: false } });
      setOffCanvasCrown({ ...offCanvasCrown, ...{ show: false } });
      setOffCanvasFilling({ ...offCanvasFilling, ...{ show: false } });
      const selectedTeethByType: EncounterTreatmentTeethType | null =
        tempTreatmentTeeth.filter(
          item => item.teeth_number === teeth_number
        )[0] || null;
      if (selectedTeethByType?.types.length > 0) {
        if (selectedTeethByType?.types.includes(8)) {
          const crown = selectedTeethByType?.crown;
          setSelectedCrown(crown as EncounterTreatmentCrownType);
          setOffCanvasCrown({ ...offCanvasCrown, ...{ show: true } });
        }
        if (selectedTeethByType?.types.includes(9)) {
          const root = selectedTeethByType?.root;

          setSelectedRoot(root as EncounterTreatmentRootType);
          setOffCanvasRoot({ ...offCanvasRoot, ...{ show: true } });
        }
        if (selectedTeethByType?.types.includes(6)) {
          const fillings = selectedTeethByType?.fillings;
          setSelectedFilling(fillings as EncounterTreatmentFillingType);
          setOffCanvasFilling({ ...offCanvasFilling, ...{ show: true } });
        }
      }
    }
  };

  const setTreatmentTeethWithType = (
    { teeth_number, crown, root, fillings }: EncounterTreatmentTeethType,
    section: keyof TeethSectionType,
    autoRemove: boolean
  ) => {
    let tempTreatmentTeeth: EncounterTreatmentTeethType[] =
      getTreatmentTeethByQuartile(section);
    tempTreatmentTeeth = tempTreatmentTeeth.map(data => {
      if (data.teeth_number == teeth_number) {
        if (
          data.types.includes(selectedTreatmentType as number) &&
          autoRemove
        ) {
          data.types = [];
          data.crown = null;
          data.root = null;
          data.fillings = null;
        } else {
          data.types = [selectedTreatmentType];
          data.crown = crown || null;
          data.root = root || null;
          data.fillings = fillings || null;
        }
      }
      return data;
    });

    setTreatmentTeethQuartile(section, tempTreatmentTeeth);
  };
  const removeTreatmentTeethWithType = (
    { teeth_number }: EncounterTreatmentTeethType,
    section: keyof TeethSectionType
  ) => {
    let tempTreatmentTeeth: EncounterTreatmentTeethType[] =
      getTreatmentTeethByQuartile(section);
    tempTreatmentTeeth = tempTreatmentTeeth.map(data => {
      if (data.teeth_number == teeth_number) {
        data.types = [];
        data.crown = null;
        data.root = null;
        data.fillings = null;
      }
      return data;
    });

    setTreatmentTeethQuartile(section, tempTreatmentTeeth);
  };
  const handleOnCrownSubmit = (crown: EncounterTreatmentCrownType) => {
    setTreatmentTeethWithType(
      {
        teeth_number: selectedTeeth,
        types: [selectedTreatmentType],
        crown
      },
      selectedSection,
      false
    );
    setOffCanvasCrown({ ...offCanvasCrown, ...{ show: false } });
    // console.log(crown);
  };
  const handleOnCrownRemove = (crown: EncounterTreatmentCrownType) => {
    if (crown) {
      removeTreatmentTeethWithType(
        {
          teeth_number: selectedTeeth,
          types: [selectedTreatmentType],
          crown
        },
        selectedSection
      );
    }

    setOffCanvasCrown({ ...offCanvasCrown, ...{ show: false } });
    // console.log(crown);
  };
  const handleOnRootRemove = (root: EncounterTreatmentRootType) => {
    if (root) {
      removeTreatmentTeethWithType(
        {
          teeth_number: selectedTeeth,
          types: [selectedTreatmentType],
          root
        },
        selectedSection
      );
    }

    setOffCanvasRoot({ ...offCanvasRoot, ...{ show: false } });
    // console.log(crown);
  };

  const handleOnFillingRemove = (fillings: EncounterTreatmentFillingType) => {
    if (fillings) {
      removeTreatmentTeethWithType(
        {
          teeth_number: selectedTeeth,
          types: [selectedTreatmentType],
          fillings
        },
        selectedSection
      );
    }

    setOffCanvasFilling({ ...offCanvasFilling, ...{ show: false } });
    // console.log(crown);
  };
  const handleOnRootSubmit = (root: EncounterTreatmentRootType) => {
    setTreatmentTeethWithType(
      {
        teeth_number: selectedTeeth,
        types: [selectedTreatmentType],
        root
      },
      selectedSection,
      false
    );
    setOffCanvasRoot({ ...offCanvasRoot, ...{ show: false } });
    // console.log(root);
  };
  const handleOnFillingSubmit = (fillings: EncounterTreatmentFillingType) => {
    setTreatmentTeethWithType(
      {
        teeth_number: selectedTeeth,
        types: [selectedTreatmentType],
        fillings
      },
      selectedSection,
      false
    );
    setOffCanvasFilling({ ...offCanvasFilling, ...{ show: false } });
    // console.log(root);
  };
  const onSubmit = async () => {
    const formValues: EncounterTreatmentType = formik.values;

    let tempTreatmentType: EncounterTreatmentTeethType[] = [];
    const treatmentTeethProperties = Object.keys(
      treatmentTeeth
    ) as (keyof TeethSectionType)[];
    treatmentTeethProperties.forEach(key => {
      tempTreatmentType = [...tempTreatmentType, ...mappedTeethValue(key)];
    });
    formValues.treatment_type = tempTreatmentType;

    if (handleOnSubmit) handleOnSubmit(formValues);
  };
  const validationSchema = Yup.object({
    treatment_plan_completed: Yup.string().required(
      t('component.form.validation.mandatory', {
        field: t('common.label.treatment_plan_completed').toLowerCase()
      })
    )
  });
  const formik = useFormik({
    initialValues: formValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit
  });

  useEffect(() => {
    onSubmit();
    // Trigger the form submission when the component mounts
    if (isTriggerCompleted || formik.dirty || formik.values) {
      formik.submitForm();
    }
  }, [
    formik.submitForm,
    formik.errors,
    formik.values,
    isTriggerCompleted,
    treatment,
    formik.dirty,
    treatmentTeeth
  ]); // The effect depends on submitForm

  const mappedTeethValue = (section: keyof TeethSectionType) => {
    return treatmentTeeth[section]
      .filter(data => data.types.length > 0)
      .map(data => {
        return data;
      });
  };

  return (
    <>
      <Form>
        <Row>
          <Col md={12}>
            <Card className="mb-5">
              <Card.Body>
                <div className="treatment--nav d-flex gap-3 justify-content-center mb-5">
                  {treatmentTypes
                    .filter(v => v.teeth_treatment == 1)
                    .map((data, i) => (
                      <button
                        key={`types-${i}`}
                        className={`btn btn-sm  btn-subtle-${data.color} ${
                          isView
                            ? data.type
                            : selectedTreatmentType == data.id &&
                              getTreatmentColor(selectedTreatmentType)
                        } rounded-pill text-uppercase `}
                        type="button"
                        onClick={() => handleOnTreatmentType(data.id as number)}
                      >
                        {data.description}
                      </button>
                    ))}
                </div>
                <div className="treatment-block">
                  <div className="treatment-block__right pe-4 d-flex flex-column align-items-center">
                    <span className="fs-9">10</span>
                    <span className="fw-bold py-2">R</span>
                    <span className="fs-9">40</span>
                  </div>
                  <div className="treatment-block__left ps-4 d-flex flex-column align-items-center">
                    <span className="fs-9">20</span>
                    <span className="fw-bold py-2">L</span>
                    <span className="fs-9">30</span>
                  </div>
                  <div className="treatment-block__middle">
                    <button className="pills">00</button>
                  </div>
                  <div className="text-center mb-4 text-body-tertiary">01</div>
                  <div className="teeth-block">
                    <div className="buttons-group-one">
                      <div className="top-button-group">
                        <div className="top-button">
                          <div className="d-flex gap-2 flex-wrap mb-5 justify-content-end">
                            {treatmentTeeth.firstQuartileTopTeeth.map(
                              (data, i) => (
                                <button
                                  key={`firstQuartileTopTeeth-${i}`}
                                  className={`teeth-block--item ${getTreatmentColor(
                                    data.types[0]
                                  )}`}
                                  type="button"
                                  onClick={() =>
                                    handleOnTeeth(
                                      data.teeth_number as number,
                                      data.types,
                                      'firstQuartileTopTeeth'
                                    )
                                  }
                                >
                                  {data.teeth_number}
                                </button>
                              )
                            )}
                          </div>
                        </div>
                        <div className="bottom-button">
                          <div className="d-flex gap-2 flex-wrap mb-5 justify-content-end">
                            {treatmentTeeth.firstQuartileBottomTeeth.map(
                              (data, i) => (
                                <button
                                  key={`firstQuartileBottomTeeth-${i}`}
                                  className={`teeth-block--item ${getTreatmentColor(
                                    data.types[0]
                                  )}`}
                                  type="button"
                                  onClick={() =>
                                    handleOnTeeth(
                                      data.teeth_number as number,
                                      data.types,
                                      'firstQuartileBottomTeeth'
                                    )
                                  }
                                >
                                  {data.teeth_number}
                                </button>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="bottom-button-group">
                        <div className="top-button">
                          <div className="d-flex gap-2 flex-wrap mb-5 justify-content-end">
                            {treatmentTeeth.fourthQuartileTopTeeth.map(
                              (data, i) => (
                                <button
                                  key={`fourthQuartileTopTeeth-${i}`}
                                  className={`teeth-block--item ${getTreatmentColor(
                                    data.types[0]
                                  )}`}
                                  type="button"
                                  onClick={() =>
                                    handleOnTeeth(
                                      data.teeth_number as number,
                                      data.types,
                                      'fourthQuartileTopTeeth'
                                    )
                                  }
                                >
                                  {data.teeth_number}
                                </button>
                              )
                            )}
                          </div>
                        </div>
                        <div className="bottom-button">
                          <div className="d-flex gap-2 flex-wrap mb-5 justify-content-end">
                            {treatmentTeeth.fourthQuartileBottomTeeth.map(
                              (data, i) => (
                                <button
                                  key={`fourthQuartileBottomTeeth-${i}`}
                                  className={`teeth-block--item ${getTreatmentColor(
                                    data.types[0]
                                  )}`}
                                  type="button"
                                  onClick={() =>
                                    handleOnTeeth(
                                      data.teeth_number as number,
                                      data.types,
                                      'fourthQuartileBottomTeeth'
                                    )
                                  }
                                >
                                  {data.teeth_number}
                                </button>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="buttons-group-two">
                      <div className="top-button-group">
                        <div className="top-button">
                          <div className="d-flex gap-2 flex-wrap mb-5 justify-content-start">
                            {treatmentTeeth.secondQuartileTopTeeth.map(
                              (data, i) => (
                                <button
                                  key={`secondQuartileTopTeeth-${i}`}
                                  className={`teeth-block--item ${getTreatmentColor(
                                    data.types[0]
                                  )}`}
                                  type="button"
                                  onClick={() =>
                                    handleOnTeeth(
                                      data.teeth_number as number,
                                      data.types,
                                      'secondQuartileTopTeeth'
                                    )
                                  }
                                >
                                  {data.teeth_number}
                                </button>
                              )
                            )}
                          </div>
                        </div>
                        <div className="bottom-button">
                          <div className="d-flex gap-2 flex-wrap mb-5 justify-content-start">
                            {treatmentTeeth.secondQuartileBottomTeeth.map(
                              (data, i) => (
                                <button
                                  key={`secondQuartileBottomTeeth-${i}`}
                                  className={`teeth-block--item ${getTreatmentColor(
                                    data.types[0]
                                  )}`}
                                  type="button"
                                  onClick={() =>
                                    handleOnTeeth(
                                      data.teeth_number as number,
                                      data.types,
                                      'secondQuartileBottomTeeth'
                                    )
                                  }
                                >
                                  {data.teeth_number}
                                </button>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="bottom-button-group">
                        <div className="top-button">
                          <div className="d-flex gap-2 flex-wrap mb-5 justify-content-start">
                            {treatmentTeeth.thirdQuartileTopTeeth.map(
                              (data, i) => (
                                <button
                                  key={`thirdQuartileTopTeeth-${i}`}
                                  className={`teeth-block--item ${getTreatmentColor(
                                    data.types[0]
                                  )}`}
                                  type="button"
                                  onClick={() =>
                                    handleOnTeeth(
                                      data.teeth_number as number,
                                      data.types,
                                      'thirdQuartileTopTeeth'
                                    )
                                  }
                                >
                                  {data.teeth_number}
                                </button>
                              )
                            )}
                          </div>
                        </div>
                        <div className="bottom-button">
                          <div className="d-flex gap-2 flex-wrap mb-5 justify-content-start">
                            {treatmentTeeth.thirdQuartileBottomTeeth.map(
                              (data, i) => (
                                <button
                                  key={`thirdQuartileBottomTeeth-${i}`}
                                  className={`teeth-block--item ${getTreatmentColor(
                                    data.types[0]
                                  )}`}
                                  type="button"
                                  onClick={() =>
                                    handleOnTeeth(
                                      data.teeth_number as number,
                                      data.types,
                                      'thirdQuartileBottomTeeth'
                                    )
                                  }
                                >
                                  {data.teeth_number}
                                </button>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-center my-4 text-body-tertiary">02</div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          {!isView && (
            <>
              <Col md={6} sm={12}>
                <Card className="h-100">
                  <Card.Body>
                    <div className="d-flex gap-9">
                      <div>
                        <h5 className="mb-3">
                          {t('common.label.other_procedures')}
                        </h5>
                        {getTreatment('01') && (
                          <Form.Check
                            type="checkbox"
                            id="whole_mouth_sdf"
                            label={t('common.label.whole_mouth_sdf')}
                            checked={formik.values.whole_mouth_sdf == 'Y'}
                            onChange={e =>
                              formik.setFieldValue(
                                'whole_mouth_sdf',
                                e.target.checked ? 'Y' : 'N'
                              )
                            }
                          />
                        )}

                        {getTreatment('01') && (
                          <Form.Check
                            type="checkbox"
                            id="fv_applied"
                            label={t('common.label.fv_applied')}
                            checked={formik.values.fv_applied == 'Y'}
                            onChange={e =>
                              formik.setFieldValue(
                                'fv_applied',
                                e.target.checked ? 'Y' : 'N'
                              )
                            }
                          />
                        )}

                        {showOrHideFieldResource('OHE_CSI') && (
                          <Form.Check
                            type="checkbox"
                            id="ohe_given"
                            label={t('common.label.ohe_given')}
                            checked={formik.values.ohe_given == 'Y'}
                            onChange={e =>
                              formik.setFieldValue(
                                'ohe_given',
                                e.target.checked ? 'Y' : 'N'
                              )
                            }
                          />
                        )}
                      </div>
                      <div>
                        <h5 className="mb-3">
                          {t('common.label.treatment_complete')}&nbsp;
                          <span className="text-danger">
                            ({t('common.label.required')})
                          </span>
                        </h5>
                        {formik.errors.treatment_plan_completed && (
                          <small className="text-danger">
                            {formik.errors.treatment_plan_completed}
                          </small>
                        )}
                        <Form.Check
                          type="radio"
                          id="treatment_plan_completed_yes"
                          label={t('common.label.yes')}
                          checked={
                            formik.values.treatment_plan_completed == 'Y'
                          }
                          onChange={e =>
                            formik.setFieldValue(
                              'treatment_plan_completed',
                              e.target.checked ? 'Y' : 'N'
                            )
                          }
                        />
                        <Form.Check
                          type="radio"
                          id="treatment_plan_completed_no"
                          label={t('common.label.no')}
                          checked={
                            formik.values.treatment_plan_completed == 'N'
                          }
                          onChange={e =>
                            formik.setFieldValue(
                              'treatment_plan_completed',
                              e.target.checked ? 'N' : 'Y'
                            )
                          }
                        />
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6} sm={12}>
                <Card>
                  <Card.Body>
                    <Card.Title> {t('common.label.notes')}</Card.Title>
                    <textarea
                      rows={5}
                      id="notes"
                      name="notes"
                      placeholder={t('common.label.notes')}
                      className="form-control form-icon-input"
                      value={formik.values.notes || ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {/*<small className="text-danger">*/}
                    {/*  <ErrorMessage name="notes" />*/}
                    {/*</small>*/}
                  </Card.Body>
                </Card>
              </Col>
            </>
          )}
        </Row>
        {handleOnNext && handleOnBack && backTab && nextTab && (
          <EncounterActionSection
            handleOnNext={handleOnNext}
            handleOnBack={handleOnBack}
            backTab={backTab}
            nextTab={nextTab}
          />
        )}
      </Form>

      <TreatmentCrownForm
        data={selectedCrown}
        isView={isView || false}
        offCanvas={offCanvasCrown}
        teeth={selectedTeeth}
        onClose={() => {
          setOffCanvasCrown({ ...offCanvasCrown, ...{ show: false } });
        }}
        onSubmit={handleOnCrownSubmit}
        onRemove={handleOnCrownRemove}
      />
      <TreatmentRootForm
        data={selectedRoot}
        isView={isView || false}
        offCanvas={offCanvasRoot}
        teeth={selectedTeeth}
        onClose={() => {
          setOffCanvasRoot({ ...offCanvasRoot, ...{ show: false } });
        }}
        onSubmit={handleOnRootSubmit}
        onRemove={handleOnRootRemove}
      />
      <TreatmentFillingForm
        data={selectedFilling}
        isView={isView || false}
        offCanvas={offCanvasFilling}
        teeth={selectedTeeth}
        onClose={() => {
          setOffCanvasFilling({ ...offCanvasFilling, ...{ show: false } });
        }}
        onSubmit={handleOnFillingSubmit}
        onRemove={handleOnFillingRemove}
      />
    </>
  );
};

export default TreatmentForm;
