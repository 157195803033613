import Search from 'assets/img/image-icons/search.svg';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

const BlankTablePreview = ({
  text,
  isPatient
}: {
  text?: string;
  isPatient?: boolean;
}) => {
  const { t }: { t: TFunction } = useTranslation();
  const fallbackText = t('common.label.no_data_to_show');
  return (
    <div className="d-flex justify-content-center text-center mb-4 pb-2">
      <div className="pt-4">
        <img width="40" src={Search} />
        {!isPatient && <h6 className="mt-2 fs-8">{text || fallbackText}</h6>}
      </div>
    </div>
  );
};

BlankTablePreview.propTypes = {};

export default BlankTablePreview;
