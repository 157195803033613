import React from 'react';

import { EncounterTreatmentFillingType } from '../../../../types/encounterManagement';
import CheckboxLabelItem from '../../../common/CheckboxLabelItem';
import { useTranslation } from 'react-i18next';
import { fillingTypes } from '../../../../data/encounterManagementData';

interface TreatmentFillingDetailProps {
  fillings: EncounterTreatmentFillingType;
}

const TreatmentFillingDetail: React.FC<TreatmentFillingDetailProps> = ({
  fillings
}) => {
  // eslint-disable-next-line
  const { t }: { t: any } = useTranslation();

  return (
    <>
      <div className="d-flex gap-4">
        <CheckboxLabelItem
          status={fillings?.filling_composite || 'N'}
          field="filling_composite"
        />
        <CheckboxLabelItem
          status={fillings?.filling_gic || 'N'}
          field="filling_gic"
        />
        <CheckboxLabelItem
          status={fillings?.filling_temporary || 'N'}
          field="filling_temporary"
        />
      </div>
      <div className="d-flex flex-column gap-4 mt-4">
        <h4> {t('common.label.surface')}</h4>
        {fillingTypes.map(field => {
          return (
            <CheckboxLabelItem
              key={field}
              status={
                (fillings?.[
                  field as keyof EncounterTreatmentFillingType
                ] as string) || 'N'
              }
              field={field as EncounterTreatmentFillingType as string}
            />
          );
        })}
      </div>
      <div className="mt-4">
        <h4> {t('common.label.details')}</h4>
        <p>{fillings.details || 'N/A'}</p>
      </div>
    </>
  );
};

export default TreatmentFillingDetail;
