import React from 'react';
import { useTranslation } from 'react-i18next';

import { EncounterTreatmentRootType } from '../../../../types/encounterManagement';
import CheckboxLabelItem from '../../../common/CheckboxLabelItem';
import { rootTypes } from '../../../../data/encounterManagementData';

interface TreatmentRootDetailProps {
  root: EncounterTreatmentRootType;
}

const TreatmentRootDetail: React.FC<TreatmentRootDetailProps> = ({ root }) => {
  // eslint-disable-next-line
  const { t }: { t: any } = useTranslation();
  return (
    <>
      <div className="d-flex flex-column gap-4">
        <h4> {t('common.label.types_of_root')}</h4>
        {rootTypes.map(field => {
          return (
            <CheckboxLabelItem
              key={field}
              status={
                (root?.[field as keyof EncounterTreatmentRootType] as string) ||
                'N'
              }
              field={field as EncounterTreatmentRootType as string}
            />
          );
        })}
      </div>
      <div className="mt-4">
        <h4> {t('common.label.additional_details')}</h4>
        <p>{root.additional_details || 'N/A'}</p>
      </div>
    </>
  );
};

export default TreatmentRootDetail;
