import { FormOptionType, ModuleFormOptionType } from 'types/settings';
import api from '../../utils/api';

const authEndpoint: string = `${process.env.REACT_APP_ENDPOINT}/mdm/form-option`;

const fetchAll = (slug?: string) => {
  if (slug) {
    return api.get(`${authEndpoint}?slug=${slug}`);
  } else {
    return api.get(`${authEndpoint}`);
  }
};
const fetchOne = (id: number) => {
  return api.get(`${authEndpoint}/${id}`);
};

const create = (data: ModuleFormOptionType) => {
  return api.post(`${authEndpoint}`, data);
};

const update = (id: number, data: FormOptionType) => {
  return api.put(`${authEndpoint}/${id}`, data);
};
const destroy = (id: number) => {
  return api.delete(`${authEndpoint}/${id}`);
};

export default { fetchAll, fetchOne, create, update, destroy };
