import React, { useEffect, useMemo } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { RouteItems, routes } from 'sitemap';
import { capitalize, isUserAdmin } from 'helpers/utils';
import {
  UilArrowFromRight,
  UilLeftArrowToLeft
} from '@iconscout/react-unicons';
import { useTranslation } from 'react-i18next';

import { useAppContext } from 'providers/AppProvider';
import Button from 'components/base/Button';
import { faFolder } from '@fortawesome/free-solid-svg-icons';

import { useBreakpoints } from 'providers/BreakpointsProvider';

import NavbarVerticalMenu from './NavbarVerticalMenu';
import NavbarVerticalCollapseProvider from './NavbarVerticalCollapseProvider';
import NavbarTopNav from '../navbar-horizontal/NavbarTopNav';

import useActivityTypeHook from '../../../hooks/actor-management/useActivityTypeHook';
import useAuthHook from '../../../hooks/useAuthHook';
import { checkScope } from '../../../helpers/auth';
import useActorHook from 'hooks/actor-management/useActorHook';

const NavbarVertical = () => {
  // eslint-disable-next-line
  const { t }: { t: any } = useTranslation();
  const {
    config: {
      navbarPosition,
      openNavbarVertical,
      navbarVerticalAppearance,
      isNavbarVerticalCollapsed
    },
    setConfig
  } = useAppContext();

  const { breakpoints } = useBreakpoints();
  const { activityTypes, fetchAllActivityType } = useActivityTypeHook();
  const { isAuthenticated } = useAuthHook();

  const { selectedActor } = useActorHook();

  useEffect(() => {
    if (isAuthenticated) {
      fetchAllActivityType().catch(e => console.log(e.message));
    }
  }, [isAuthenticated]);
  const selectedActivityTypes = useMemo(() => {
    if (checkScope('actor-activitytype-list')) {
      if (selectedActor?.activity_types) {
        return selectedActor?.activity_types;
      } else {
        if (isUserAdmin()) {
          return activityTypes;
        } else {
          return [];
        }
      }
    } else {
      return [];
    }
  }, [activityTypes, selectedActor]);

  const routesMemo = useMemo(() => {
    let mappedRoutes: RouteItems[] = JSON.parse(JSON.stringify(routes));

    mappedRoutes = mappedRoutes
      .filter(routeParent =>
        routeParent.permission ? checkScope(routeParent.permission) : true
      )
      .map(route => {
        route.pages = route.pages
          .filter(data =>
            data.permission ? checkScope(data.permission) : true
          )
          .map(route2 => {
            if (route2.pages) {
              route2.pages = route2.pages?.filter(data =>
                data.permission ? checkScope(data.permission) : true
              );
            } else {
              route2.pages = undefined;
            }

            return route2;
          });
        return route;
      });

    const activityTypesNavItem: RouteItems = {
      label: selectedActor?.name || '',
      horizontalNavLabel: 'home',
      active: true,
      pages: []
    };

    selectedActivityTypes.forEach(activity => {
      const path = selectedActor
        ? `/activity-type/${activity.id}/${activity.venue_type_id}/actor/${selectedActor.id}/schools`
        : `/activity-type/${activity.id}/${activity.venue_type_id}/`;

      activityTypesNavItem.pages.push({
        name: activity.name,
        is_dynamic: true,
        path,
        pathName: `activity-type${activity.name}`,
        active: true,
        icon: activity.icon || faFolder
      });
    });

    mappedRoutes.splice(1, 0, activityTypesNavItem);
    return mappedRoutes;
  }, [selectedActivityTypes, selectedActor]);

  return (
    <NavbarVerticalCollapseProvider>
      <Navbar
        className="navbar-vertical"
        expand="lg"
        variant=""
        data-navbar-appearance={
          navbarVerticalAppearance === 'darker' ? 'darker' : ''
        }
      >
        <Navbar.Collapse id="navbarVerticalCollapse" in={openNavbarVertical}>
          <div className="navbar-vertical-content">
            <Nav className="flex-column" as="ul" id="navbarVerticalNav">
              {routesMemo.map(route => (
                <React.Fragment key={route.label}>
                  <Nav.Item key={route.label} className="mt-1">
                    {!route.labelDisabled && (
                      <>
                        <p className="navbar-vertical-label">
                          {capitalize(route.label)}
                        </p>
                        <hr className="navbar-vertical-line" />
                      </>
                    )}

                    <NavbarVerticalMenu level={1} routes={route.pages} />
                  </Nav.Item>
                </React.Fragment>
              ))}
            </Nav>

            {navbarPosition === 'combo' && breakpoints.down('lg') && (
              <div className="move-container">
                <div className="navbar-vertical-divider"></div>
                <NavbarTopNav />
              </div>
            )}
          </div>
        </Navbar.Collapse>

        <div className="navbar-vertical-footer">
          <Button
            className="navbar-vertical-toggle border-0 fw-semibold w-100 white-space-nowrap d-flex align-items-center"
            onClick={() => {
              setConfig({
                isNavbarVerticalCollapsed: !isNavbarVerticalCollapsed
              });
            }}
          >
            {isNavbarVerticalCollapsed ? (
              <UilArrowFromRight size={16} className="mb-1" />
            ) : (
              <>
                <UilLeftArrowToLeft size={16} className="mb-1" />
                <span className="ms-2">
                  {t(`common.action.collapsed_view`)}
                </span>
              </>
            )}
          </Button>
        </div>
      </Navbar>
    </NavbarVerticalCollapseProvider>
  );
};

export default NavbarVertical;
