import api from '../../utils/api';
import { PatientType } from '../../types/patientManagement';
import axios, { CancelTokenSource } from 'axios';

const authEndpoint: string = `${process.env.REACT_APP_ENDPOINT}/patient`;
const advancePatientListEndpoint: string = `${process.env.REACT_APP_ENDPOINT}/patient/advance`;
let cancelTokenSource: CancelTokenSource | null = null;
const fetchAllWithoutPagination = (
  search?: string,
  actorId?: string | null,
  venueId?: string | null,
  grade?: string | null,
  isFlag?: string
) => {
  const params = new URLSearchParams();

  if (search) params.append('search', search);
  if (actorId) params.append('actor_id', actorId);
  if (venueId) params.append('venue_id', venueId);
  if (grade) params.append('grade', grade);
  if (isFlag) params.append('is_flagged', isFlag);

  if (cancelTokenSource) {
    cancelTokenSource.cancel('Operation canceled due to new request.');
  }
  // Create a new cancel token
  cancelTokenSource = axios.CancelToken.source();

  return api.get(`${authEndpoint}?${params.toString()}`, {
    cancelToken: cancelTokenSource.token
  });
};

const fetchAll = (
  page: number,
  limit: number,
  search?: string,
  actorId?: string | null,
  venueId?: string | null,
  grade?: string | null,
  isFlag?: string,
  age_range?: string | null
) => {
  const params = new URLSearchParams();

  if (search) params.append('search', search);
  if (actorId) params.append('actor_id', actorId);
  if (venueId) {
    params.append('venue_id', venueId);
    params.append('venue_ids[]', venueId);
  }
  if (grade) params.append('grade', grade);
  if (isFlag) params.append('is_flagged', isFlag);
  if (age_range) params.append('age_range', age_range);

  if (cancelTokenSource) {
    cancelTokenSource.cancel('Operation canceled due to new request.');
  }
  // Create a new cancel token
  cancelTokenSource = axios.CancelToken.source();

  return api.get(
    `${advancePatientListEndpoint}?page=${page}&limit=${limit}&${params.toString()}`,
    {
      cancelToken: cancelTokenSource.token
    }
  );
};

const fetchOne = (id: number) => {
  return api.get(`${authEndpoint}/${id}`);
};

const create = (data: PatientType) => {
  return api.post(`${authEndpoint}`, data);
};

const update = (id: number, data: PatientType) => {
  return api.put(`${authEndpoint}/${id}`, data);
};
const destroy = (id: number) => {
  return api.delete(`${authEndpoint}/${id}`);
};
const countByVenueId = (venueId: number) => {
  return api.get(`${authEndpoint}/count/${venueId}`);
};
const fetchAllFormSetting = (model: string) => {
  return api.get(`${authEndpoint}/formSetting/get?model=${model}`);
};
const toggleFlag = (id: number) => {
  return api.get(`${authEndpoint}/toogle-flag/${id}`);
};
export default {
  fetchAllWithoutPagination,
  fetchAll,
  fetchOne,
  create,
  update,
  destroy,
  countByVenueId,
  fetchAllFormSetting,
  toggleFlag
};
