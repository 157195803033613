import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { UilCalendarAlt } from '@iconscout/react-unicons';
import './css/CustomDatePicker.css';

type CustomDatePickerProps = {
  value: Date | string | null;
  onChange: (date: Date | null) => void;
  placeholder?: string;
  minDate?: Date;
  maxDate?: Date;
  dateFormat?: string;
  showTimeSelect?: boolean;
  showTimeSelectOnly?: boolean;
  id?: string;
  name?: string;
};
const CustomDatePicker = ({
  showTimeSelect,
  showTimeSelectOnly,
  value,
  onChange,
  placeholder = 'Select Date',
  minDate,
  maxDate,
  ...rest
}: CustomDatePickerProps) => {
  const years = Array.from(
    { length: moment().year() - 1900 + 100 },
    (_, i) => 1900 + i
  );
  const months = moment.months();

  return (
    <div className="customDatePicker">
      <DatePicker
        selected={value as Date}
        onChange={onChange}
        showTimeSelect={showTimeSelect}
        showTimeSelectOnly={showTimeSelectOnly}
        showIcon
        dateFormat={showTimeSelect ? 'yyyy-MM-dd h:mm aa' : 'yyyy-MM-dd'}
        minDate={minDate}
        maxDate={maxDate}
        placeholderText={placeholder}
        icon={
          <UilCalendarAlt
            className="flatpickr-icon text-body-tertiary"
            size={16}
          />
        }
        customInput={<input className="form-control" />}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled
        }) => (
          <div
            style={{
              margin: 10,
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <button
              type="button"
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
              style={{
                padding: '5px 10px',
                fontSize: '16px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                cursor: prevMonthButtonDisabled ? 'not-allowed' : 'pointer'
              }}
            >
              {'<'}
            </button>
            <select
              value={moment(date).year()}
              onChange={({ target: { value } }) => changeYear(parseInt(value))}
            >
              {years.map(year => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>

            <select
              value={months[moment(date).month()]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
            >
              {months.map(month => (
                <option key={month} value={month}>
                  {month}
                </option>
              ))}
            </select>

            <button
              type="button"
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
              style={{
                padding: '5px 10px',
                fontSize: '16px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                cursor: nextMonthButtonDisabled ? 'not-allowed' : 'pointer'
              }}
            >
              {'>'}
            </button>
          </div>
        )}
        {...rest}
      />
    </div>
  );
};

export default CustomDatePicker;
