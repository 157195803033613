import { useDispatch, useSelector } from '../../store';
import EncounterService from '../../services/encounter-management/EncounterService';
import { EncounterType } from '../../types/encounterManagement';
import {
  addOneEncounter,
  removeOneEncounter,
  setAllEncounter,
  setAllEncounterTabSetting,
  setOneEncounter,
  updateOneEncounter
} from '../../store/reducers/encounter-management/encounterSlice';
import { TabSetting } from '../../types';
import { checkScope } from '../../helpers/auth';

const useEncounterHook = () => {
  const dispatch = useDispatch();
  const { encounters, encounter, encounterTabFormSettings } = useSelector(
    state => state.encounter
  );

  const fetchAllEncounter = async ({
    patientId
  }: {
    patientId: number;
  }): Promise<EncounterType[]> => {
    try {
      const resp = await EncounterService.fetchAll({ patientId });
      const results = resp?.data?.rows || [];
      dispatch(setAllEncounter({ results }));
      return results;
    } catch (e) {
      console.log('fetchAllEncounter', e);
      throw e;
    }
  };
  const createEncounter = async (
    data: EncounterType
  ): Promise<EncounterType> => {
    try {
      const resp = await EncounterService.create(data);
      const row = resp?.data?.data || null;

      dispatch(addOneEncounter({ row }));
      return row;
    } catch (e) {
      console.log('addOneEncounter', e);
      throw e;
    }
  };
  const updateEncounter = async (id: number, data: EncounterType) => {
    try {
      const resp = await EncounterService.update(id, data);
      const row = resp?.data?.data || null;
      dispatch(updateOneEncounter({ id, row }));
    } catch (e) {
      console.log('getOneEncounter', e);
      throw e;
    }
  };
  const fetchOneEncounter = async (id: number): Promise<EncounterType> => {
    try {
      const resp = await EncounterService.fetchOne(id);
      const row = resp?.data?.data || null;
      dispatch(setOneEncounter({ row }));
      return row;
    } catch (e) {
      dispatch(setOneEncounter({ row: null }));
      console.log('fetchOneEncounter', e);
      throw e;
    }
  };
  const deleteEncounter = async (id: number) => {
    try {
      await EncounterService.destroy(id);
      dispatch(removeOneEncounter({ id }));
    } catch (e) {
      console.log('getOneEncounter', e);
      throw e;
    }
  };
  const fetchAllFormSettingEncounter = async (model: string) => {
    try {
      const resp = await EncounterService.fetchAllFormSetting(model);

      return resp?.data?.data || [];
    } catch (e) {
      console.log('fetchAllFormSettingEncounter', e);
      throw e;
    }
  };
  const fetchAllTabSettingEncounter = async (
    type: string
  ): Promise<TabSetting[]> => {
    try {
      const resp = await EncounterService.fetchAllTabSetting(type);
      const rolePrefix = 'encounter-tabs';
      const results: TabSetting[] = resp?.data?.data || [];
      const mappedResults = results.filter(item => {
        if (item.value == 'History' && checkScope(`${rolePrefix}-history`)) {
          return true;
        } else if (
          item.value == 'Screening' &&
          checkScope(`${rolePrefix}-screening`)
        ) {
          return true;
        } else if (
          item.value == 'Periodontal' &&
          checkScope(`${rolePrefix}-periodontal`)
        ) {
          return true;
        } else if (
          item.value == 'Diagnostic' &&
          (checkScope(`${rolePrefix}-diagonostic`) ||
            checkScope(`${rolePrefix}-diagnostic`))
        ) {
          return true;
        } else if (
          item.value == 'Treatment' &&
          checkScope(`${rolePrefix}-treatment`)
        ) {
          return true;
        } else if (
          item.value == 'Referral' &&
          checkScope(`${rolePrefix}-referral`)
        ) {
          return true;
        } else {
          return false;
        }
      });
      dispatch(setAllEncounterTabSetting({ results: mappedResults }));
      return results;
    } catch (e) {
      console.log('fetchAllTabSettingEncounter', e);
      throw e;
    }
  };
  return {
    fetchAllEncounter,
    fetchOneEncounter,
    createEncounter,
    deleteEncounter,
    updateEncounter,
    fetchAllFormSettingEncounter,
    fetchAllTabSettingEncounter,
    encounters,
    encounterTabFormSettings,
    encounter
  };
};

export default useEncounterHook;
