import api from '../../utils/api';

const authEndpoint: string = `${process.env.REACT_APP_ENDPOINT}/encounter/treatment/treatmentType`;

const fetchAll = () => {
  return api.get(`${authEndpoint}`);
};

export default {
  fetchAll
};
