import { useEffect, useState } from 'react';
import { getIntegerArrayBetween } from 'helpers/utils';

interface UsePagination {
  currentPageNo: number;
  totalPage: number;
  maxPaginationButtonCount: number;
}

const usePagination = ({
  currentPageNo,
  totalPage,
  maxPaginationButtonCount
}: UsePagination) => {
  const [visiblePaginationItems, setVisiblePaginationItems] = useState<
    number[]
  >([]);
  const [hasNextEllipsis, setHasNextEllipsis] = useState(false);
  const [hasPrevEllipsis, setHasPrevEllipsis] = useState(false);

  function calculateEnd(
    start: number,
    currentPageNo: number,
    maxPaginationButtonCount: number,
    maxButtonEachSide: number,
    totalPage: number
  ) {
    if (start === 1) {
      return maxPaginationButtonCount;
    }

    if (currentPageNo + maxButtonEachSide >= totalPage) {
      return totalPage;
    }

    return currentPageNo + maxButtonEachSide;
  }

  useEffect(() => {
    if (totalPage <= maxPaginationButtonCount) {
      setVisiblePaginationItems(
        Array.from(Array(totalPage).keys()).map((_, i) => i + 1)
      );
    } else {
      const maxButtonEachSide = Math.floor(maxPaginationButtonCount / 2);

      const start =
        currentPageNo <= maxButtonEachSide
          ? 1
          : currentPageNo - maxButtonEachSide;

      const end = calculateEnd(
        start,
        currentPageNo,
        maxPaginationButtonCount,
        maxButtonEachSide,
        totalPage
      );

      if (start > 1 && start !== 2) {
        setHasPrevEllipsis(true);
      } else {
        setHasPrevEllipsis(false);
      }

      if (end >= totalPage) {
        setHasNextEllipsis(false);
      } else {
        setHasNextEllipsis(true);
      }
      setVisiblePaginationItems(getIntegerArrayBetween(start, end));
    }
  }, [currentPageNo, totalPage, maxPaginationButtonCount]);

  return { visiblePaginationItems, hasNextEllipsis, hasPrevEllipsis };
};

export default usePagination;
