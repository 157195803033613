import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import useEncounterHook from '../../hooks/encounter-management/useEncounterHook';

import { Form, Modal, ModalProps, OffcanvasProps } from 'react-bootstrap';

import {
  EncounterReasonType,
  EncounterType
} from '../../types/encounterManagement';
import { toast } from 'react-toastify';
import { validationErrorType } from '../../types';
import {
  getEncounterReason,
  getEncounterReasonLabel
} from '../../helpers/utils';
import Button from '../../components/base/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit,
  faEye,
  faPlus,
  faTrashCan
} from '@fortawesome/free-solid-svg-icons';
import ValidationErrorMessage from '../../components/common/ValidationErrorMessage';
import {
  checkExpiredEncounter,
  convertTimestampToHumanDate,
  convertTimestampToHumanDateTimezone
} from '../../helpers/date';
import { PatientType } from '../../types/patientManagement';
import { useNavigate } from 'react-router-dom';
import { confirmAlert } from '../../components/common/ConfirmAlert';
import useAuthHook from '../../hooks/useAuthHook';
import EncounterDetail from '../../components/details/encounter-management/EncounterDetail';
import { checkScope } from '../../helpers/auth';
import { ActivityType } from '../../types/actorManagement';
import useActivityTypeHook from '../../hooks/actor-management/useActivityTypeHook';
import useEncounterReason from '../../hooks/encounter-management/useEncounterReason';

export interface EncounterPageProps {
  patient: PatientType;
  activityTypeId: number;
  venueId: number;
}

const EncounterPage = ({
  patient,
  activityTypeId,
  venueId
}: EncounterPageProps) => {
  // eslint-disable-next-line
  const { t }: { t: any } = useTranslation();
  const { tenant } = useAuthHook();
  const page = t('common.label.encounters');
  const { encounters, fetchAllEncounter, deleteEncounter } = useEncounterHook();

  // const { activityType } = useActivityTypeHook();
  const [loader, setLoader] = useState<{ list?: boolean; form?: boolean }>({
    list: false,
    form: false
  });

  const initialFormValues = {
    id: null,
    venue_id: venueId as number,
    patient_id: patient.id as number,
    encounter_reason: ''
  };

  const [encounter, setEncounter] = useState<EncounterType>(initialFormValues);
  const [encounterDetail, setEncounterDetail] = useState<EncounterType>();
  const [modal, setModal] = useState<ModalProps>({
    show: false
  });

  const [validationError, setValidationError] = useState<validationErrorType>({
    show: false
  });
  const [offCanvasDetail, setOffCanvasDetail] = useState<OffcanvasProps>({
    show: false,
    placement: 'end'
  });
  const navigate = useNavigate();

  const { reasons, fetchAllEncounterReason } = useEncounterReason();

  const { fetchOneActivityType } = useActivityTypeHook();
  const [activityType, setActivityType] = useState<ActivityType>();

  useEffect(() => {
    fetchAllItem();
    fetchOneActivityTypeItem();
    if (!reasons?.length) {
      fetchAllEncounterReason().catch(e => console.log(e));
    }
  }, [patient]);

  useEffect(() => {
    if (activityTypeId) {
      fetchOneActivityType(activityTypeId as number)
        .then(resp => setActivityType(resp))
        .catch(e => console.log(e));
    }
  }, [activityTypeId]);
  useEffect(() => {
    fetchAllFormOptionItem();
  }, [activityType]);
  // eslint-disable-next-line
  const [activityReasons, setActivityReasons] = useState<EncounterReasonType[]>(
    []
  );

  // eslint-disable-next-line
  const handleOnChange = (e: any) => {
    setEncounter({ ...encounter, ...{ encounter_reason: e.target.value } });
  };
  const handleOnView = (data: EncounterType) => {
    // console.log(data)
    setEncounterDetail(data);
    setOffCanvasDetail({ ...offCanvasDetail, ...{ show: true } });
  };
  const handleOnAdd = () => {
    if (
      patient &&
      activityType &&
      activityReasons &&
      activityReasons.length == 1
    ) {
      setEncounter({
        ...encounter,
        ...{ encounter_reason: activityReasons[0].value }
      });

      if (activityReasons[0].value)
        redirectToEncounterPage(null, activityReasons[0]);

      // if (tempEncounter.encounter_reason) createItem(tempEncounter);
    } else {
      setValidationError({ show: false });
      setEncounter(initialFormValues);
      setModal({ ...modal, ...{ show: true } });
    }
  };

  const handleOnSubmit = () => {
    setLoader({ form: true });
    setModal({ ...modal, ...{ show: true } });
    const encounterDetail = getEncounterReason(
      reasons,
      encounter.encounter_reason as string
    );
    if (encounterDetail) redirectToEncounterPage(null, encounterDetail);
  };

  // const checkExpiredEncounter = (date:string) => {
  //  const  encounterTimeLimit=tenant?.encounter_time_limit || 0;
  //  let isExpired=true;
  //   if(encounterTimeLimit > 0 && date){
  //     const encounterDateWithAddHour=addHourToTimestamp(date,24)
  //     if(isAfterCurrentTimestamp(date,24)){
  //       isExpired=false;
  //     }
  //   }
  //   return isExpired
  // };

  const fetchAllItem = () => {
    setLoader({ list: true });
    fetchAllEncounter({ patientId: patient.id as number })
      .then(() => {
        setLoader({ list: false });
      })
      .catch(e => {
        console.log(e);
        toast.error(t('common.message.failed'));
        setLoader({ list: false });
      });
  };
  const fetchAllFormOptionItem = () => {
    const tempActivityReasons: string[] = activityType?.reasons || [];
    setActivityReasons(
      reasons.filter(data => tempActivityReasons.includes(data.value || ''))
    );
  };
  const fetchOneActivityTypeItem = () => {
    // if (patient.activity_type_id) {
    //   fetchOneActivityType(patient.activity_type_id as number)
    //     .then(() => {})
    //     .catch(e => console.log(e));
    // }
  };
  const deleteItem = (data: EncounterType) => {
    const name = getEncounterReasonLabel(
      reasons,
      data.encounter_reason as string
    );
    setLoader({ list: true });
    if (data.id) {
      deleteEncounter(data.id)
        .then(() => {
          toast.success(
            t('common.message.success.delete', {
              page,
              name: `${name}(${convertTimestampToHumanDate(data.created_at)})`
            })
          );
          setLoader({ list: false });
        })
        .catch(e => {
          console.log(e);
          toast.error(t('common.message.failed'));
          setLoader({ list: false });
        });
    }
  };
  const handleOnDelete = (data: EncounterType) => {
    const name = getEncounterReasonLabel(
      reasons,
      data.encounter_reason as string
    );
    confirmAlert({
      title: `${t('component.dialog.delete.title')}`,
      message: `${t('component.dialog.delete.body', { name: `${name}(${convertTimestampToHumanDate(data.created_at)})` })}`
    }).then(resp => {
      if (resp && data) {
        deleteItem(data);
      }
    });
  };
  const redirectToEncounterPage = (
    id: number | null,
    reason?: EncounterReasonType | null
  ) => {
    const params = new URLSearchParams();
    if (id) params.append('id', `${id}`);
    if (activityTypeId) params.append('activityTypeId', `${activityTypeId}`);
    if (patient?.id) params.append('patientId', `${patient?.id as number}`);
    if (venueId) params.append('venueId', `${venueId as number}`);
    if (reason?.id) params.append('reasonId', `${reason?.id}`);
    if (reason?.id && activityTypeId && patient?.id && venueId) {
      navigate(
        `/activity-type/${activityTypeId}/encounter-tab?${params.toString()}`
      );
    }
  };
  const renderModal = () => {
    return (
      <Modal
        show={modal.show}
        onHide={() => setModal({ ...modal, ...{ show: false } })}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('common.label.add')} {t('common.label.encounter')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {validationError && <ValidationErrorMessage {...validationError} />}
            {activityReasons.map((data, i) => (
              <Form.Check
                key={`reason-${i}`}
                type="radio"
                id={data.name}
                label={data.name}
                value={data.value}
                name="reason"
                onClick={handleOnChange}
              />
            ))}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="subtle-secondary"
            onClick={() => setModal({ ...modal, ...{ show: false } })}
          >
            Close
          </Button>
          <Button
            disabled={loader.form || !encounter.encounter_reason}
            variant="primary"
            onClick={handleOnSubmit}
          >
            {t('common.action.continue')}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const renderList = () => {
    return (
      <>
        {encounters.map((data, i) => {
          return (
            <div key={`reason-list-${i}`} className="d-flex flex-column">
              <hr />
              <h5 className="text-body-highlight lh-sm">
                {getEncounterReasonLabel(
                  reasons,
                  data.encounter_reason as string
                )}
              </h5>
              <p className="fs-9 mb-0">
                <span className="text-primary me-1">
                  {data?.created_by_name || 'N/A'}
                </span>
                |
                <span className="ms-2">
                  {convertTimestampToHumanDateTimezone(data.created_at)}
                </span>
              </p>
              <p className="fs-9 mb-0">{data?.venue_name}</p>
              <div className="d-flex gap-2 mt-3">
                {checkScope('encounter-read') && (
                  <Button
                    variant="subtle-secondary"
                    className="btn-circle"
                    onClick={() => handleOnView(data)}
                  >
                    <FontAwesomeIcon icon={faEye} />
                  </Button>
                )}

                {!checkExpiredEncounter(
                  data.created_at as string,
                  tenant?.encounter_time_limit
                ) &&
                  checkScope('encounter-update') && (
                    <Button
                      variant="subtle-info"
                      className="btn-circle"
                      onClick={() => {
                        const encounterDetail = getEncounterReason(
                          reasons,
                          data.encounter_reason as string
                        );
                        redirectToEncounterPage(
                          data.id as number,
                          encounterDetail
                        );
                      }}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </Button>
                  )}

                {!checkExpiredEncounter(
                  data.created_at as string,
                  tenant?.encounter_time_limit
                ) &&
                  checkScope('encounter-delete') && (
                    <Button
                      variant="subtle-danger"
                      className="btn-circle"
                      onClick={() => handleOnDelete(data)}
                    >
                      <FontAwesomeIcon icon={faTrashCan} />
                    </Button>
                  )}
              </div>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <h4>{page}</h4>
        {checkScope('encounter-create') && (
          <Button variant="primary" onClick={handleOnAdd}>
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            {t('common.action.add')} {page}
          </Button>
        )}

        {renderModal()}
      </div>

      {renderList()}
      {encounterDetail && (
        <EncounterDetail
          offCanvas={offCanvasDetail}
          encounter={encounterDetail}
          patient={patient}
          activity_type={activityType as ActivityType}
          encounter_reason={getEncounterReasonLabel(
            reasons,
            encounterDetail.encounter_reason as string
          )}
          onEdit={redirectToEncounterPage}
          onClose={() =>
            setOffCanvasDetail({ ...offCanvasDetail, ...{ show: false } })
          }
        />
      )}
    </div>
  );
};
export default EncounterPage;
