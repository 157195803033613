import api from '../../utils/api';
import { Venue } from '../../types/actorManagement';
import { serializedObject } from '../../helpers/utils';
import { VenueFilterType } from '../../types';

const authEndpoint: string = `${process.env.REACT_APP_ENDPOINT}/actor-management/venue`;

const fetchAllWithPaginate = (params: VenueFilterType) => {
  return api.get(`${authEndpoint}/advance?${serializedObject(params)}`);
};

const fetchAll = (
  actorId?: string,
  schoolType?: string,
  venueTypeId?: string
) => {
  const params = new URLSearchParams();

  if (actorId) params.append('actor_id', actorId);
  if (schoolType) params.append('type', schoolType);
  if (venueTypeId) params.append('venue_type_id', venueTypeId);

  return api.get(`${authEndpoint}?${params.toString()}`);
};

const fetchOne = (id: number) => {
  return api.get(`${authEndpoint}/${id}`);
};

const create = (data: Venue) => {
  return api.post(`${authEndpoint}`, data);
};

const update = (id: number, data: Venue) => {
  return api.put(`${authEndpoint}/${id}`, data);
};
const destroy = (id: number) => {
  return api.delete(`${authEndpoint}/${id}`);
};
const toggleFlag = (id: number) => {
  return api.get(`${authEndpoint}/toogle-flag/${id}`);
};

export default {
  fetchAllWithPaginate,
  fetchAll,
  fetchOne,
  create,
  update,
  destroy,
  toggleFlag
};
