import EncounterReasonService from 'services/encounter-management/EncounterReasonService';
import { EncounterReasonType } from 'types/encounterManagement';
import {
  addOneEncounterReason,
  removeOneEncounterReason,
  setAllEncounterReason,
  updateOneEncounterReason
} from 'store/reducers/encounter-management/encounterSlice';
import { useDispatch, useSelector } from '../../store';

const useEncounterReason = () => {
  const dispatch = useDispatch();
  const { reasons } = useSelector(state => state.encounter);

  const fetchAllEncounterReason = async (): Promise<EncounterReasonType[]> => {
    try {
      const resp = await EncounterReasonService.fetchAll();
      const results = resp?.data?.data?.rows || [];
      dispatch(setAllEncounterReason({ results }));
      return results;
    } catch (e) {
      console.log('fetchAllEncounterReason', e);
      throw e;
    }
  };

  const createEncounterReason = async (
    data: EncounterReasonType
  ): Promise<EncounterReasonType> => {
    try {
      const resp = await EncounterReasonService.create(data);
      const row = resp?.data?.data || null;

      dispatch(addOneEncounterReason({ row }));
      return row;
    } catch (e) {
      console.log('addOneEncounterReason', e);
      throw e;
    }
  };

  const updateEncounterReason = async (
    id: number,
    data: EncounterReasonType
  ) => {
    try {
      const resp = await EncounterReasonService.update(id, data);
      const row = resp?.data?.data || null;
      dispatch(updateOneEncounterReason({ id, row }));
    } catch (e) {
      console.log('getOneEncounterReason', e);
      throw e;
    }
  };

  const deleteEncounterReason = async (id: number) => {
    try {
      await EncounterReasonService.destroy(id);
      dispatch(removeOneEncounterReason({ id }));
    } catch (e) {
      console.log('getOneEncounterReason', e);
      throw e;
    }
  };
  return {
    fetchAllEncounterReason,
    createEncounterReason,
    deleteEncounterReason,
    updateEncounterReason,
    reasons
  };
};

export default useEncounterReason;
