import FormOptionService from 'services/settings/FormOptionService';
import { FormOptionType, ModuleFormOptionType } from 'types/settings';
import { useDispatch, useSelector } from '../../store';
import {
  addOneFormOption,
  setAllFormOption
} from '../../store/reducers/settings/formOptionSlice';

const useFormOptionHook = () => {
  const dispatch = useDispatch();
  const { formOptions } = useSelector(state => state.formOption);

  const fetchAllFormOption = async (
    slug?: string
  ): Promise<FormOptionType[]> => {
    try {
      const resp = await FormOptionService.fetchAll(slug);
      const results = resp?.data?.data || [];
      if (!slug) {
        dispatch(setAllFormOption({ results }));
      }

      return results;
    } catch (e) {
      console.log('fetchAllFormOption', e);
      throw e;
    }
  };
  const storeFormOption = async (
    data: ModuleFormOptionType
  ): Promise<FormOptionType> => {
    try {
      const resp = await FormOptionService.create(data);
      const row = resp?.data?.data || null;
      dispatch(addOneFormOption({ row }));
      return row;
    } catch (e) {
      console.log('addOneFormOption', e);
      throw e;
    }
  };

  return {
    fetchAllFormOption,
    storeFormOption,
    formOptions
  };
};

export default useFormOptionHook;
